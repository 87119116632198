import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { actions } from "../redux/authRedux";
import * as Yup from "yup";
import "../../src/assets/css/login.css";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import sign from "jwt-encode";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Login() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({ isAuthorized: auth.user != null }),
    shallowEqual
  );

  const { hash } = useParams();

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const createConfig = (_token) => {
    return {
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Content-Type': 'application/json; charset=UTF-8',
        'search': ''
      }
    }
  }

  const ValidSchema = Yup.object().shape({
    username: Yup.string().trim().nullable().required("Vui lòng nhập tên đăng nhập"),
    password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
  });


  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: ValidSchema,
    onSubmit: (values, { setSubmitting }) => {
      const secure = sign(values, "3$bN5WHT1DgZ");
      // console.log(secure);
      Axios.post(URL_LIST_ALL + "ldaplogin", { data: secure })
        .then((res) => {
          setSubmitting(false);
          if (res.data.status === "success") {
            let user = res.data;
            user.config = createConfig(res.data.access_token);
            // console.log(user);
            dispatch(actions.login(user));
          } else alert(res.data.message)
        }).catch((err) => console.log(err));
    },
  });

  const formikMK = useFormik({
    initialValues: { email: '', code: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().nullable().required('Nhập email').max(150, "Độ dài không quá 150 ký tự"),
      code: Yup.string().trim().nullable().required('Nhập mã nhân viên').max(15, "Độ dài không quá 15 ký tự"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true)

      Axios.post(URL_LIST_ALL + 'quen-mat-khau', values).then(res => {
        setLoading(false);
        setSubmitting(false);
        if (res.data.status === "success") {
          resetForm();
          confirmAlert({
            title: 'Thành công',
            message: 'Mật khẩu mới đã được gửi tới email ' + values.email + '. Vui lòng kiểm tra email',
            buttons: [{ label: 'OK' }]
          });
          document.cookie = "ssohash=;path=/;domain=bhsgroup.vn";
        } else {
          confirmAlert({
            title: 'Lỗi',
            message: res.data.message,
            buttons: [{ label: 'OK' }]
          });
        }
      }).catch(err => console.log(err));

    },
  });

  useEffect(function () {
    if (Cookies.get('ssohash') && Cookies.get('ssohash') !== '' && Cookies.get('ssohash') !== 'logout') {
      let ssohash = Cookies.get('ssohash')
      // console.log(ssohash);
      // Axios.post(URL_LIST_ALL + "loginhash", { ssohash: ssohash }).then((res) => {
      //   if (res.data.status === "success") {
      //     let user = res.data;
      //     user.config = createConfig(res.data.access_token);
      //     dispatch(actions.login(user));
      //   } else alert(res.data.message)
      // }).catch((err) => console.log(err));
    }
  }, [hash]);

  return (
    <div>
      {isAuthorized ? (
        <Redirect to="/" />
      ) : (
        <div className="layout-login">
          <div className="container btn-login-gr">
            <div className="mb-3 w-100 d-flex justify-content-center">
              <img src="https://bhsgroup.vn/wp-content/uploads/2021/12/Asset-14@4x.png" width={300} alt="upload" />
            </div>
            <div className="mt-3 w-100 d-flex justify-content-center">
              <div className="wrap-login100 p-t-50 p-b-30">


                <form className="login100-form validate-form"
                  method="post"
                  onSubmit={formik.handleSubmit}
                >
                  {/* <small className="w-100 text-center mt-3 text-white">Hoặc đăng nhập bằng tài khoản và mật khẩu của phần mềm chấm công</small> */}
                  <div className="wrap-input100 validate-input ">
                    <input
                      className="input100"
                      type="text"
                      name="username"
                      placeholder="Tên đăng nhập"
                      {...formik.getFieldProps("username")}
                    />
                    <span className="focus-input100"></span>
                  </div>
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-danger">{formik.errors.username}</div>
                  ) : null}

                  <div className="wrap-input100 validate-input mt-3">
                    <span className="btn-show-pass">
                      <i className="fa fa fa-eye"></i>
                    </span>
                    <input className="input100" type="password" name="password" placeholder="Mật khẩu" {...formik.getFieldProps("password")} />
                    <span className="focus-input100"></span>

                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}
                  <div className="container-login100-form-btn mt-3">
                    <button
                      type="submit"
                      className="login100-form-btn"
                      disabled={formik.isSubmitting}
                    >
                      Đăng nhập
                    </button>

                    <div className="text-center text-white w-100 mt-3">
                      Đối với nhân viên mới, anh chị vui lòng đăng nhập webmail <a className="text-info" target="_blank" href="https://mail.bhsgroup.vn/">mail.bhsgroup.vn</a> để kích hoạt tài khoản trước khi đăng nhập hệ thống
                    </div>
                  </div>
                  {/* <div className="w-100 mt-3">
                    <p>- Tên đăng nhập là mã nhân viên hoặc email đã đăng ký trên hệ thống chấm công</p>
                    <p>- Mật khẩu là mật khẩu của tài khoản trên hệ thống chấm không, Không phải mật khẩu email công ty cung cấp </p>
                    <p>- Trường hợp chưa có mật khẩu: <button type="button" className="btn btn-sm text-danger" onClick={() => setShow(true)}>Lấy mật khẩu</button></p>
                  </div> */}

                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal show={show} onHide={() => setShow(false)} animation={true} size="md">

        <Modal.Header closeButton>
          <Modal.Title>Lấy mật khẩu</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className="new-added-form" method="post" onSubmit={formikMK.handleSubmit}>
            <div className="row">
              <div className="col-12 mb-3">
                <p>Lưu ý: Đây là email và mật khẩu của phần mềm chấm công không phải của Gmail, Outlook</p>
              </div>
              <div className="col-12 form-group">
                <label>Email <span className="text-danger">*</span></label>
                <input type="text" placeholder="Nhập email" className="form-control height32" {...formikMK.getFieldProps("email")} autoComplete="off" />
                <AlertErrorForm formik={formikMK} name="email"></AlertErrorForm>
              </div>
              <div className="col-xs-12"></div>
              <div className="col-12 form-group">
                <label>Mã nhân viên <span className="text-danger">*</span></label>
                <input type="text" placeholder="Nhập mã nhân viên" className="form-control height32" {...formikMK.getFieldProps("code")} autoComplete="off" />
                <AlertErrorForm formik={formikMK} name="code"></AlertErrorForm>
              </div>
              <div className="col-xs-12"></div>

              <div className="col-12 form-group mt-3 d-flex justify-content-center">
                <button type="submit" disabled={formikMK.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lấy mật khẩu</>}</button>
              </div>

            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { LIST_NATION } from "../configs";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import SearchBoxModal from "../component/SearchBoxModal";
import { Link } from "react-router-dom";
import { checkRole } from "../model";
import Loading from "../component/Loading";
// import UploadModal from "../component/UploadModal";
// import LoadCustomizer from "../component/LoadCustomizer";

export default function FormUsersPage() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadhd, setLoadhd] = useState(false);
    const [detail, setDetail] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    // const [listMauHopDong, setListMauHopDong] = useState([]);
    // const [mauHopDongChoice, setMauHopDongChoice] = useState(null);
    // const [listHopDong, setListHopDong] = useState([]);
    const [dateSelect, setDateSelect] = useState({
        birthday: null, cccd_date: null, birthday_child_1: null, birthday_child_2: null, birthday_child_3: null, datejoining: null, ngaynghiviec: null, listhopdong: null, checkin_status_date: null,
        datehdtv_start: null, datehdtv_end: null, datehdld_start_1: null, datehdld_end_1: null, datehdld_start_2: null, datehdld_end_2: null, datehdld_start_3: null, datehdld_end_3: null, hd_status: null, ungphep: 0
    });
    const [newCode, setNewCode] = useState();
    const allowSetRoles = checkRole(user, 'list-users', 'roles');
    const rolesAllCompany = checkRole(user, 'list-users', 'viewallcompany');
    const checkLeave = checkRole(user, 'list-users', 'leave');

    const defaultdetail = {
        author_id: user.data.id,
        fullname: '',
        code: '',
        number_code: '',
        oldcode: '',
        sex: '',
        mobile: '',
        email: '',
        birthday: '',
        nation: '',
        address: '',
        status: 3,
        cccd: '',
        company_id: null,
        part_id: null,
        chucvu_id: '',
        datejoining: '',
        role_id: 2,
        typeaccount: 2,
        dateCCCD: '',
        cccd_address: '',
        address_tc: '',
        dctc: '',
        bank_id: '',
        bank_address: '',
        bank_city: '',
        bhxh_id: '',
        mst_id: '',
        qualification: '',
        majors: '',
        highschool: '',
        marital_status: '',
        fullname_love: '',
        total_child: '',
        fullname_child_1: '',
        birthday_child_1: '',
        fullname_child_2: '',
        birthday_child_2: '',
        fullname_child_3: '',
        birthday_child_3: '',
        time_test: '',
        datehdtv_start: '',
        datehdtv_end: '',
        datehdld_start_1: '',
        datehdld_end_1: '',
        datehdld_start_2: '',
        datehdld_end_2: '',
        datehdld_start_3: '',
        datehdld_end_3: '',
        hd_status: '',
        luong_ct: '',
        ngaynghiviec: '',
        checkin_status: 1,
        checkin_status_date: '',
        usertype: 1,
        ungphep: 0,
        ungphep_year: 0,
        total_phep: 0
    }
    useEffect(
        function () {
            // console.log(checkLeave);
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                const dataif = res.data.data
                                if (!dataif.leave) dataif.leave = 0
                                if (!dataif.songaybu) dataif.songaybu = 0
                                setDetail(dataif);
                                setDateSelect({
                                    birthday: dataif.birthday, cccd_date: dataif.cccd_date, birthday_child_1: dataif.birthday_child_1, birthday_child_2: dataif.birthday_child_2, birthday_child_3: dataif.birthday_child_3, datejoining: dataif.datejoining, ngaynghiviec: dataif.ngaynghiviec, checkin_status_date: dataif.checkin_status_date,
                                    datehdtv_start: dataif.datehdtv_start, datehdtv_end: dataif.datehdtv_end, datehdld_start_1: dataif.datehdld_start_1, datehdld_end_1: dataif.datehdld_end_1, datehdld_start_2: dataif.datehdld_start_2, datehdld_end_2: dataif.datehdld_end_2, datehdld_start_3: dataif.datehdld_start_3, datehdld_end_3: dataif.datehdld_end_3
                                })
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    useEffect(() => {
        if (id > 0) { } else {
            Axios.get(URL_LIST_ALL + "render_code?usertype=1" + (companyId ? '&company_id=' + companyId : ''), user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setNewCode(res.data.code)
                    formik.setFieldValue('number_code', res.data.number_code)
                    formik.setFieldValue('code', res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }

    }, [companyId, id])

    let validForm = {
        fullname: Yup.string().trim().nullable().required('Nhập họ và tên ').max(150, "Độ dài không quá 150 ký tự"),
        code: Yup.string().trim().nullable().required('Nhập mã số nhân viên'),
        sex: Yup.string().trim().nullable().required('Chọn giới tính'),
        master_code: Yup.string().trim().nullable().required('Chọn quản lý trực tiếp'),
        cccd: Yup.string().trim().nullable().required('Vui lòng nhập CCCD để tránh trùng lặp'),
        role_id: Yup.string().trim().nullable().when("typeaccount", (type) => {
            if (type < 2) return Yup.string().required("Chọn quyền hạn tài khoản")
        })
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];

            setLoading(true)
            // console.log(values);
            // values.listhopdong = listHopDong;
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'users/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'users', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    const convertDate = (value) => {
        return moment(value).format('YYYY-MM-DD')
    }

    useEffect(function () {
        if (id > 0 && detail) {
            let uCode = detail.code
            Axios.get(URL_LIST_ALL + "cong_nhansu?user_code=" + uCode, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (res.data.data && res.data.data[0]) {
                            const congnhasu = res.data.data[0]
                            formik.setFieldValue('leave', congnhasu.songayphep ? congnhasu.songayphep : '')
                            formik.setFieldValue('songaybu', congnhasu.songaybu ? congnhasu.songaybu : '')
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            Axios.get(URL_LIST_ALL + "ungphep_story?user_code=" + uCode, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (res.data.data && res.data.data[0]) {
                            const congnhasu = res.data.data[0]
                            formik.setFieldValue('ungphep_year', congnhasu.total_phep ? congnhasu.total_phep : '')
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }

    }, [detail, id]);// eslint-disable-line
    // useEffect(() => {//Load danh sách hợp đồng của nhân sự này
    //     if (user && user.data.id) {
    //         Axios.get(URL_LIST_ALL + "hopdong?user_id=" + user.data.id, user.config)
    //             .then(async (res) => {
    //                 if (res.data.status === "success") {
    //                     setListHopDong(res.data.data)
    //                 } else console.log(res.data.message);
    //             })
    //             .catch((err) => console.log(err));
    //     }

    // }, [user, loadhd])
    // const addHopDong = () => {
    //     if (mauHopDongChoice) {
    //         mauHopDongChoice.user_id = id
    //         Axios.post(URL_LIST_ALL + "hopdong", mauHopDongChoice, user.config)
    //             .then(async (res) => {
    //                 if (res.data.status === "success") {
    //                     setLoadhd(!loadhd)
    //                 } else console.log(res.data.message);
    //             })
    //             .catch((err) => console.log(err));
    //     }
    // }
    // const removeHopDong = (index) => {
    //     Axios.delete(URL_LIST_ALL + "hopdong/" + index, user.config)
    //         .then(async (res) => {
    //             if (res.data.status === "success") {
    //                 setLoadhd(!loadhd)
    //             } else console.log(res.data.message);
    //         })
    //         .catch((err) => console.log(err));
    // }
    const updateMybhs = () => {
        if (detail && detail.id) {
            setLoading(true);
            Axios.post('https://mybhsapi.bhsgroup.vn/api/update_info', { user_id: detail.id }, user.config).then(res => {
                setLoading(false);
                setAutoRefresh(!autoRefresh)
                if (res.data.status === "success") {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Cập nhập mybhs thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                    setLoading(false);
                }
            }).catch(err => console.log(err));
        }
    }
    return (
        <AkkhorLayout idrole="list-users">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-users">Quản lý nhân viên</Link>
                    </li>
                    <li>{id ? 'Sửa tài khoản' : 'Thêm mới tài khoản'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} tài khoản</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            {rolesAllCompany ?
                                <div className="col-xl-3 col-lg-6 col-12 form-group">
                                    <label>Công ty </label>
                                    <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" colvalue={'id'} colname={'title'} defaultValue={formik.values['company_id']} name='company_id'
                                        onChange={async (e) => {
                                            setCompanyId(e)
                                            await formik.setFieldValue('company_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="company_id"></AlertErrorForm>
                                </div>
                                :
                                <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("company_id")} />
                            }
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Phòng ban </label>
                                <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} defaultValue={formik.values['part_id']} name='part_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('part_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="part_id"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chức vụ </label>
                                <SearchBoxModal apiname={"chucvu"} placeholder="Chọn chức vụ" colvalue={'id'} colname={'title'} defaultValue={formik.values['chucvu_id']} name='chucvu_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('chucvu_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="chucvu_id"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Mã số nhân viên  <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("code")} defaultValue={newCode} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>MYBHS ID </label>
                                <div className="input-group mb-3">
                                    <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("mybhs_id")} autoComplete="off" />
                                    <div className="input-group-prepend">
                                        <button className="btn btn-sm btn-success" type="button" onClick={updateMybhs}>Update</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Mã nhân viên cũ</label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("oldcode")} autoComplete="off" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Giới tính <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("sex")}>
                                    <option value="" disabled>Hãy chọn giới tính</option>
                                    <option value="Nam">Nam</option>
                                    <option value="Nữ">Nữ</option>
                                    <option value="Khác">Khác</option>
                                </select>
                                <AlertErrorForm formik={formik} name="sex"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày sinh <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số CCCD/CMND: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd")} />
                                <AlertErrorForm formik={formik} name="cccd"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày cấp CCCD/CMND: </label>
                                <DateTimePicker onChange={(value) => { handleDate('cccd_date', value) }} value={dateSelect.cccd_date ? new Date(dateSelect.cccd_date) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Nơi cấp CCCD/CMND: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd_address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ thường trú: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address_tc")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ liên lạc</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Điện thoại <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Email</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
                                <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Dân tộc</label>
                                <select className="form-select height32" {...formik.getFieldProps("nation")}>
                                    <option value="" >Chọn dân tộc</option>
                                    {LIST_NATION.map((nation, index) => <option value={nation} key={index.toString()}>{nation}</option>)}
                                </select>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trình độ chuyên môn: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("qualification")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chuyên ngành: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("majors")} />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trường đào tạo: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("highschool")} />
                            </div>

                            <div className="col-12 mt-3" ></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tình trạng hôn nhân</label>
                                <select className="form-select height32" {...formik.getFieldProps("marital_status")}>
                                    <option value="Chưa kết hôn" >Chưa kết hôn</option>
                                    <option value="Đã kết hôn" >Đã kết hôn</option>
                                    <option value="Đã ly hôn" >Đã ly hôn</option>
                                </select>
                            </div>
                            {((formik.values.marital_status && formik.values.marital_status === "Đã kết hôn") || (formik.values.marital_status && formik.values.marital_status === "Đã ly hôn")) &&
                                <>
                                    {formik.values.marital_status === "Đã kết hôn" &&
                                        <>
                                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                                <label>Họ Tên vợ/chồng: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_love")} />
                                            </div>
                                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                                <label>Số điện thoại vợ/chồng: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("phone_love")} />
                                            </div>
                                        </>
                                    }

                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Số con cái: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("total_child")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 1: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_1")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 1: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_1', value) }} value={dateSelect.birthday_child_1 ? new Date(dateSelect.birthday_child_1) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 2: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_2")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 2: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_2', value) }} value={dateSelect.birthday_child_2 ? new Date(dateSelect.birthday_child_2) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 3: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_3")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 3: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_3', value) }} value={dateSelect.birthday_child_3 ? new Date(dateSelect.birthday_child_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                </>
                            }

                            <div className="col-12 mt-3" ></div>


                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Người duyệt đơn </label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn nhân sự" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['master_code']} name='master_code'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('master_code', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="master_code"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Người quản lý trực tiếp</label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn nhân sự" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['master_mybhs']} name='master_mybhs'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('master_mybhs', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="master_mybhs"></AlertErrorForm>
                            </div>
                            {checkLeave ?
                                <>
                                    <div className="col-xl-2 col-lg-6 col-12 form-group">
                                        <label>Số ngày phép còn lại</label>
                                        <input type="number" placeholder="" className="form-control height32" {...formik.getFieldProps("leave")} />
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-12 form-group">
                                        <label>Số phép đã ứng</label>
                                        <input type="number" placeholder="" className="form-control height32" {...formik.getFieldProps("ungphep")} />
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-12 form-group">
                                        <label>Số phép đã ứng trong năm</label>
                                        <input type="number" placeholder="" disabled className="form-control height32" {...formik.getFieldProps("ungphep_year")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tổng số ngày đã nghỉ phép trong năm</label>
                                        <input type="number" placeholder="" disabled className="form-control height32" {...formik.getFieldProps("tongphepnghi")} />
                                    </div>
                                </>
                                : null}

                            {allowSetRoles && <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Quyền <span className="text-danger">*</span></label>
                                <SearchBoxModal apiname={"roles"} placeholder="Chọn quyền" colvalue={'id'} colname={'title'} defaultValue={formik.values['role_id']} name='role_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('role_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="role_id"></AlertErrorForm>
                            </div>}

                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chấm công</label>
                                <select className="form-select height32" {...formik.getFieldProps("checkin_status")}>
                                    <option value="1">Có phải chấm công</option>
                                    <option value="2">Không phải chấm công</option>
                                    <option value="3">Không trong danh sách chấm công</option>
                                </select>
                            </div>
                   
                            {formik.values.checkin_status == 2 &&
                                <div className="col-12 col-sm-6 col-lg-3 form-group">
                                    <label>Ngày bắt đầu không phải chấm công</label>
                                    <DateTimePicker onChange={(value) => { handleDate('checkin_status_date', value) }} value={dateSelect.checkin_status_date ? new Date(dateSelect.checkin_status_date) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                </div>
                            }

                            <div className="col-12 mt-3" ></div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Thời gian bắt đầu ca sáng</label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("timestart_sa")} />
                            </div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Thời gian kết thúc ca sáng</label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("timeend_sa")} />
                            </div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Thời gian bắt đầu ca chiều</label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("timestart_ch")} />
                            </div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Thời gian kết thúc ca chiều</label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("timeend_ch")} />
                            </div>

                            <div className="col-12 mt-3" ><hr /></div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số TK ngân hàng techcombank:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_id")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chi nhánh:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tỉnh/Thành phố:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_city")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số sổ BHXH: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bhxh_id")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Mã số thuế: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mst_id")} />
                            </div>
                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày gia nhập <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('datejoining', value) }} value={dateSelect.datejoining ? new Date(dateSelect.datejoining) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="datejoining"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái làm việc: </label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="5">Cộng tác viên</option>
                                    <option value="4">Thực tập sinh</option>
                                    <option value="3">Chưa nhận việc</option>
                                    <option value="2">Đang thử việc</option>
                                    <option value="1">Đang chính thức</option>
                                    <option value="0">Đã Nghỉ</option>
                                </select>
                            </div>
                            {(formik.values.status === 0 || formik.values.status === "0") &&
                                <div className="col-xl-3 col-lg-6 col-12 form-group">
                                    <label>Ngày nghỉ việc: </label>
                                    <DateTimePicker onChange={(value) => { handleDate('ngaynghiviec', value) }} value={dateSelect.ngaynghiviec ? new Date(dateSelect.ngaynghiviec) : ''} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                </div>
                            }
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Thời gian thử việc (tháng): </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("time_test")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày bắt đầu HĐ thử việc: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdtv_start', value) }} value={dateSelect.datehdtv_start ? new Date(dateSelect.datehdtv_start) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày kết thúc HĐ thử việc: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdtv_end', value) }} value={dateSelect.datehdtv_end ? new Date(dateSelect.datehdtv_end) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Lương thử việc (%): </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("time_test")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày bắt đầu HĐLĐ lần 1: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdld_start_1', value) }} value={dateSelect.datehdld_start_1 ? new Date(dateSelect.datehdld_start_1) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày kết thúc HĐLĐ lần 1: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdld_end_1', value) }} value={dateSelect.datehdld_end_1 ? new Date(dateSelect.datehdld_end_1) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày bắt đầu HĐLĐ lần 2: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdld_start_2', value) }} value={dateSelect.datehdld_start_2 ? new Date(dateSelect.datehdld_start_2) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày kết thúc HĐLĐ lần 2: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdld_end_2', value) }} value={dateSelect.datehdld_end_2 ? new Date(dateSelect.datehdld_end_2) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày bắt đầu HĐLĐ lần 3: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdld_start_3', value) }} value={dateSelect.datehdld_start_3 ? new Date(dateSelect.datehdld_start_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày kết thúc HĐLĐ lần 3: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datehdld_end_3', value) }} value={dateSelect.datehdld_end_3 ? new Date(dateSelect.datehdld_end_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tình trạng hợp đồng: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("hd_status")} />
                            </div>

                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Lương chính thức: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("luong_ct")} />
                            </div>

                            <div className="col-12 mt-3" ><hr /></div>
                            {/* <div className="col-12 form-group">
                                <label>Tài liệu hợp đồng: </label>
                                <div className="row">
                                    <div className="col-3">
                                        <label>Chọn hợp đồng: </label>
                                        <select className="form-select height32" onChange={(e) => { setMauHopDongChoice({ type_id: e.target.value, title: e.target.selectedOptions[0].text }) }} value={null}>
                                            <option value="">Chọn tài liệu hợp đồng</option>
                                            {listMauHopDong && listMauHopDong.map(function (item, i) {
                                                return (<option value={item.id} key={i}>{item.title}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <label>Mức lương: </label>
                                        <input type="text" className="form-control height32" placeholder="Mức lương" onChange={(e) => { setMauHopDongChoice({...mauHopDongChoice,luong:e.target.value}) }} />
                                    </div>
                                    <div className="col-2">
                                        <label>Ngày bắt đầu: </label>
                                        <DateTimePicker onChange={(value) => { setMauHopDongChoice({...mauHopDongChoice,datestart:moment(value).format('YYYY-MM-DD')}) }} value={ mauHopDongChoice && mauHopDongChoice.datestart? new Date(mauHopDongChoice.datestart) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-2">
                                        <label>Ngày kết thúc: </label>
                                        <DateTimePicker onChange={(value) => { setMauHopDongChoice({...mauHopDongChoice,dateend:moment(value).format('YYYY-MM-DD')}) }} value={ mauHopDongChoice && mauHopDongChoice.dateend? new Date(mauHopDongChoice.dateend) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>

                                    </div>
                                    <div className="col-2">
                                        <label>Ngày tạo: </label>
                                        <DateTimePicker onChange={(value) => { setMauHopDongChoice({...mauHopDongChoice,datecreated:moment(value).format('YYYY-MM-DD')}) }} value={ mauHopDongChoice && mauHopDongChoice.datecreated? new Date(mauHopDongChoice.datecreated) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-1 text-center">
                                        <label>#</label>
                                        <button type="button" className="btn btn-sm btn-info height32" onClick={addHopDong}><span className="fas fa-plus text-white"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                {listHopDong && listHopDong.map(function (item, i) {
                                    return (<div className="row mb-1" key={i}>
                                        <div className="col-3">
                                            <input type="text" className="form-control height32" readOnly value={item.title} />
                                        </div>
                                        <div className="col-2">
                                            <input type="text" className="form-control height32" placeholder="Mức lương" readOnly value={item.luong} />
                                        </div> 
                                        <div className="col-2 form-group">
                                            <DateTimePicker value={item.datestart ? new Date(item.datestart) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                                        </div>
                                        <div className="col-2 form-group">
                                            <DateTimePicker value={item.dateend ? new Date(item.dateend) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                                        </div>
                                        <div className="col-2 form-group">
                                            <DateTimePicker value={item.datecreated ? new Date(item.datecreated) : null} format="dd-MM-y" className="form-control height32 air-datepicker" />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center">
                                            <button type="button" className="btn btn-sm btn-danger height32" onClick={() => removeHopDong(item.id)}><span className="fas fa-trash-alt"></span></button>
                                            {item.file_url && <a href={item.file_url} target="_blank"><span className="fas fa-download"></span></a>}
                                        </div>
                                    </div>)
                                })}

                            </div>
                            <div className="col-12 mt-3" ><hr /></div> */}
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu tài khoản</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {loading && (<Loading />)}
        </AkkhorLayout>
    );
}

import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { LIST_NATION } from "../configs";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";


export default function FormUsersPage() {
    const { user } = useSelector((state) => state.auth);
    const info = user && user.data ? user.data : null;
    const id = info.id;
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [dateSelect, setDateSelect] = useState({ birthday: null, cccd_date: null, birthday_child_1: null, birthday_child_2: null, birthday_child_3: null });

    const defaultdetail = {
        typeaccount: 0,
        author_id: user.data.id,
        fullname: '',
        code: '',
        sex: '',
        mobile: '',
        email: '',
        birthday: '',
        address: '',
        status: 1,
        cccd: '',
        dateCCCD: '',
        cccd_address: '',
        dctc: '',
        bank_id: '',
        bank_address: '',
        bank_city: '',
        bhxh_id: '',
        mst_id: '',
        qualification: '',
        majors: '',
        highschool: '',
        marital_status: '',
        fullname_love: '',
        total_child: '',
        fullname_child_1: '',
        birthday_child_1: '',
        fullname_child_2: '',
        birthday_child_2: '',
        fullname_child_3: '',
        birthday_child_3: '',
        datestart_sa: '',
        dateend_sa: '',
        datestart_ch: '',
        dateend_ch: '',
    }
    useEffect(function () {
        let mounted = true;
        Axios.get(URL_LIST_ALL + "users/" + id, user.config)
            .then(async (res) => {
                if (res.data.status === "success") {
                    // console.log(res.data.data)
                    if (mounted) {
                        setDetail(res.data.data);
                        const dataif = res.data.data
                        setDateSelect({ birthday: dataif.birthday, cccd_date: dataif.cccd_date, birthday_child_1: dataif.birthday_child_1, birthday_child_2: dataif.birthday_child_2, birthday_child_3: dataif.birthday_child_3 })
                    }
                } else console.log(res.data.message);
            })
            .catch((err) => console.log(err));
        return () => (mounted = false);
    }, [user, id, autoRefresh]);
    let validForm = {
        fullname: Yup.string().trim().nullable().required('Nhập họ và tên ').max(150, "Độ dài không quá 150 ký tự"),
        typeaccount: Yup.string().trim().nullable().required('Vui lòng chọn loại tài khoản'),
        code: Yup.string().trim().nullable().required('Nhập mã số nhân viên'),
        sex: Yup.string().trim().nullable().required('Chọn giới tính'),
        birthday: Yup.string().trim().nullable().required('Chọn ngày sinh'),
        mobile: Yup.string().trim().nullable().required('Nhập số điện thoại'),
        email: Yup.string().trim().nullable().required('Nhập địa chỉ email'),
        role_id: Yup.string().trim().nullable().when("typeaccount", (type) => {
            if (type < 2) return Yup.string().required("Chọn quyền hạn tài khoản")
        })
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            console.log(values);

            Axios.put(URL_LIST_ALL + 'users/' + id, values, user.config).then(res => {
                setLoading(false);
                setSubmitting(false);
                setAutoRefresh(!autoRefresh)
                if (res.data.status === "success") {
                    setAutoRefresh(!autoRefresh)
                    resetForm();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật tài khoản thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));

        },
    });


    const handleDate = (name, value) => {
        // console.log(date);
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)

    };


    return (
        <AkkhorLayout idrole="dashboard">
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} tài khoản</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Giới tính <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("sex")}>
                                    <option value="" disabled>Hãy chọn giới tính</option>
                                    <option value="Nam">Nam</option>
                                    <option value="Nữ">Nữ</option>
                                    <option value="Khác">Khác</option>
                                </select>
                                <AlertErrorForm formik={formik} name="sex"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày sinh <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : new Date()} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Điện thoại <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Dân tộc</label>
                                <select className="form-select height32" {...formik.getFieldProps("nation")}>
                                    <option value="" >Chọn dân tộc</option>
                                    {LIST_NATION.map((nation, index) => <option value={nation} key={index.toString()}>{nation}</option>)}
                                </select>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số CCCD/CMND: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày cấp CCCD/CMND: </label>
                                <DateTimePicker onChange={(value) => { handleDate('cccd_date', value) }} value={dateSelect.cccd_date ? new Date(dateSelect.cccd_date) : new Date()} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Nơi cấp CCCD/CMND: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd_address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ thường trú: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address_tc")} />
                            </div>

                            <div className="col-12 mt-3" ><hr /></div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số sổ BHXH: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bhxh_id")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Mã số thuế: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mst_id")} />
                            </div>
                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số TK ngân hàng techcombank:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_id")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chi nhánh:</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tỉnh/Thành Phố:</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_city")} />
                            </div>
                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trình độ chuyên môn: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("qualification")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chuyên ngành: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("majors")} />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trường đào tạo: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("highschool")} />
                            </div>

                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tình trạng hôn nhân</label>
                                <select className="form-select height32" {...formik.getFieldProps("marital_status")}>
                                    <option value="Chưa kết hôn" >Chưa kết hôn</option>
                                    <option value="Đã kết hôn" >Đã kết hôn</option>
                                </select>
                            </div>
                            {formik.values.marital_status && formik.values.marital_status === "Đã kết hôn" &&
                                <>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Họ Tên vợ/chồng: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_love")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Số con cái: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("total_child")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 1: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_1")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 1: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_1', value) }} value={dateSelect.birthday_child_1 ? new Date(dateSelect.birthday_child_1) : null} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 2: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_2")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 2: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_2', value) }} value={dateSelect.birthday_child_2 ? new Date(dateSelect.birthday_child_2) : null} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 3: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_3")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 3: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_3', value) }} value={dateSelect.birthday_child_3 ? new Date(dateSelect.birthday_child_3) : null} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                </>
                            }
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Cập nhập hồ sơ</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}

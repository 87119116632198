
import React from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function ListCompany() {

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "datecreate",label: "Ngày tạo",}
    
  ];


  return (<AkkhorLayout idrole="list-company">
    <div className="breadcrumbs-area">
      <ul>
          <li>
              <Link to="/">Trang chủ</Link>
          </li>
          
          <li>Quản lý công ty</li>
      </ul>
    </div>
    <SRList name="company" idrole="list-company" title="Công ty" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
  </AkkhorLayout>);
}

import React, { useEffect, useState } from "react";
import AkkhorLayout from '../layout/akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";

export default function ProfilePage() {
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(user);
    useEffect(()=>{
        Axios.get(URL_LIST_ALL  + "users/"+user.data.id+"?select_info=1",user.config)
            .then( async (res) => {
            if (res.data.status === "success") {
                setDetail(res.data.data)
            } else console.log(res.data.message);
            })
            .catch((err) => console.log(err));
    },[user]);// eslint-disable-line
  return (
    <AkkhorLayout idrole="my-profile">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>Hồ sơ của tôi</li>
            </ul>
        </div>
        
        {detail && <div className="card height-auto">
            <div className="card-body">
            {console.log(detail)}
                <div className="single-info-details">
                    <div className="item-content">
                        <div className="header-inline item-header">
                            <h3 className="text-dark-medium font-medium">{detail.fullname}</h3>
                            <div className="header-elements mt-2">
                                <ul>
                                    <li><Link to="/update-profile" title="Cập nhật hồ sơ" className='btn btn-sm btn-success'><i className="far fa-edit"></i> Cập nhật hồ sơ</Link></li>
                                    {/* <li><Link to="/change-password" title="Thay đổi mật khẩu" className='btn btn-sm btn-info'><i className="fas fa-lock"></i> Thay đổi mật khẩu </Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <h5>Thông tin cá nhân:</h5>
                            <div className="col-lg-6 info-table table-responsive">
                                
                                <div className="row">
                                       
                                    <div className="col-5">Họ và tên:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.fullname}</div>
                                
                                    <div className="col-5">Mã số nhân viên:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.code}</div>
        
                                    {detail.company_title &&
                                        <>
                                            <div className="col-5">Công ty:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.company_title}</div>
                                        </>
                                    }
                                    {detail.part_title &&
                                        <>
                                            <div className="col-5">Phòng ban:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.part_title}</div>
                                        </>
                                    }
                                        {detail.chucvu &&
                                        <>
                                            <div className="col-5">Chức vụ:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.chucvu}</div>
                                        </>
                                    }
                                       
                                    <div className="col-5">Email:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.email}</div>
                                
                                    <div className="col-5">Số điện thoại:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.mobile}</div>
                                
                                
                                    <div className="col-5">Ngày gia nhập:</div>
                                    <div className="col-7 font-medium text-dark-medium">{dateFormat(detail.datejoining,'dd-mm-yyyy')}</div>
                                
                                    <div className="col-5">Giới tính:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.sex}</div>
                                
                                    <div className="col-5">Ngày sinh:</div>
                                    <div className="col-7 font-medium text-dark-medium">{dateFormat(detail.birthday,'dd-mm-yyyy')}</div>
                                
                                    <div className="col-5">Dân tộc:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.nation}</div>
                                
                                    <div className="col-5">Địa chỉ thường chú:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.address_tc ? detail.address_tc : "Đang cập nhập"}</div>
                                    
                                    <div className="col-5">Địa chỉ liên lạc:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.address}</div>
                                
                                </div>
                            </div>
                            <div className="col-lg-6 info-table table-responsive">
                                <div className="row">
                                       
                                    <div className="col-5">STKNH Techcombank:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.bank_id ? detail.bank_id : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Số sổ BHXH:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.bhxh_id ? detail.bhxh_id : "Đang cập nhập"}</div>

                                    <div className="col-5">MS Thuế:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.mst_id ? detail.mst_id : "Đang cập nhập"}</div>
                                    
                                    <div className="col-5">Số CCCD/CMND:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.cccd ? detail.cccd : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày cấp CCCD/CMND:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.cccd_date ? dateFormat(detail.cccd_date,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Nơi cấp CCCD/CMND:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.cccd_address ? detail.cccd_address : "Đang cập nhập"}</div>
                                

                                    <div className="col-5">Địa chỉ thường trú:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.address_tc ? detail.address_tc : "Đang cập nhập"}</div>
                                        

                                    <div className="col-5">Trường đào tạo:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.highschool ? detail.highschool : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Trình độ chuyên môn:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.qualification ? detail.qualification : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Chuyên ngành:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.majors ? detail.majors : "Đang cập nhập"}</div>
                                       
                                </div>
                            </div>
                        </div>
                        <div><hr /></div>
                        <div className="row">
                            <h5>Thông tin Hợp đồng:</h5>
                            <div className="col-lg-6 info-table table-responsive">
                                <div className="row">
                                
                                    <div className="col-5">Thời gian thử việc:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.time_test ? detail.time_test : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày bắt đầu HĐ thử việc:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdtv_start ? dateFormat(detail.datehdtv_start,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày kết thúc HĐ thử việc:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdtv_end ? dateFormat(detail.datehdtv_end,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Tình trạng hợp đồng:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.hd_status ? detail.hd_status : "Đang cập nhập"}</div>
                                 
                                </div>
                            </div>
                            <div className="col-lg-6 info-table table-responsive">
                                <div className="row">
                                
                                <div className="col-5">Ngày bắt đầu HĐLĐ lần 1:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdld_start_1 ? dateFormat(detail.datehdld_start_1,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày kết thúc HĐLĐ lần 1:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdld_end_1 ? dateFormat(detail.datehdld_end_1,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày bắt đầu HĐLĐ lần 2:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdld_start_2 ? dateFormat(detail.datehdld_start_2,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày kết thúc HĐLĐ lần 2:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdld_end_2 ? dateFormat(detail.datehdld_end_2,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày bắt đầu HĐLĐ lần 3:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdld_start_3 ? dateFormat(detail.datehdld_start_3,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                    <div className="col-5">Ngày kết thúc HĐLĐ lần 3:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.datehdld_end_3 ? dateFormat(detail.datehdld_end_3,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                
                                </div>
                            </div>
                        </div>
                        <div><hr /></div>
                        <div className="row">
                            <h5>Tình trạng hôn nhân:</h5>
                            <div className="col-lg-6 info-table table-responsive">
                                <div className="row">
                                    
                                    <div className="col-5">Tình trạng hôn nhân:</div>
                                    <div className="col-7 font-medium text-dark-medium">{detail.marital_status ? detail.marital_status : "Đang cập nhập"}</div>
                                       
                                    {detail.marital_status && detail.marital_status ==="Đã kết hôn" && 
                                        <>
                                        <div className="col-5">Họ tên vợ/chồng:</div>
                                        <div className="col-7 font-medium text-dark-medium">{detail.fullname_love ? detail.fullname_love : "Đang cập nhập"}</div>
                                    
                                        <div className="col-5">Số điện thoại:</div>
                                        <div className="col-7 font-medium text-dark-medium">{detail.phone_love ? detail.phone_love : "Đang cập nhập"}</div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 info-table table-responsive">
                                <div className="row">
                                       
                                    {detail.marital_status && detail.marital_status ==="Đã kết hôn" && 
                                        <>
                                        <div className="col-5">Tổng số con:</div>
                                        <div className="col-7 font-medium text-dark-medium">{detail.total_child ? detail.total_child : "Đang cập nhập"}</div>
                                        {detail.fullname_child_1 &&
                                            <>  
                                            <div className="col-5">Họ tên con 1:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.fullname_child_1 ? detail.fullname_child_1 : "Đang cập nhập"}</div>
                                        
                                            <div className="col-5">Ngày sinh con 1:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.birthday_child_1 ? dateFormat(detail.birthday_child_1,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                            </>
                                        }
                                        {detail.fullname_child_2 &&
                                            <>
                                                <div className="col-5">Họ tên con 2:</div>
                                                <div className="col-7 font-medium text-dark-medium">{detail.fullname_child_2 ? detail.fullname_child_2 : "Đang cập nhập"}</div>
                                            
                                                <div className="col-5">Ngày sinh con 2:</div>
                                                <div className="col-7 font-medium text-dark-medium">{detail.birthday_child_2 ? dateFormat(detail.birthday_child_2,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                            </>
                                        }
                                        {detail.fullname_child_3 &&
                                            <>
                                            <div className="col-5">Họ tên con 3:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.fullname_child_3 ? detail.fullname_child_3 : "Đang cập nhập"}</div>
                                        
                                            <div className="col-5">Ngày sinh con 3:</div>
                                            <div className="col-7 font-medium text-dark-medium">{detail.birthday_child_3 ? dateFormat(detail.birthday_child_3,'dd-mm-yyyy') : "Đang cập nhập"}</div>
                                            </>
                                        }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}    
    </AkkhorLayout>
  );
}

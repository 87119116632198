import { Modal } from "react-bootstrap";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import Pagination from "react-js-pagination";

export default function SearchBoxModal(props) {
    const { apiname, data, placeholder, name, onChange, colvalue, colname, defaultValue, all, autoSelect, onDataList, disabled } = props;
    const { user } = useSelector((state) => state.auth);
    const [value, setValue] = useState('');
   
    const [title, setTitle] = useState('');
    const [show, setShow] = useState(false)
    const [list, setList] = useState(data ? data : []);
    const [page, setPage] = useState(1);
    const [param, setParam] = useState('?');
    const [totalRecord, setTotalRecord] = useState(10);

    useEffect(() => {
        // console.log(defaultValue);
        const valueS = defaultValue
        setValue(defaultValue)
        if (valueS && list) {
            setTimeout(() => {
                const rowVal = list.find((e) => { return e.value === valueS })
                if (typeof rowVal !== "undefined") {
                    setTitle(rowVal.label)
                } else {
                    let config = {
                        headers: {
                            'Authorization': 'Bearer ' + user.access_token,
                            'asselect': JSON.stringify([colvalue + " as value", colname + " as label"])
                        }
                    }
                    Axios.get(URL_LIST_ALL + apiname + '?' + colvalue + '=' + valueS, config).then((res) => {
                        if (res.data && res.data.status === "success") {
                            const _data = res.data.data
                            if (_data && _data[0]) setTitle(_data[0].label)
                        } else console.log(res.data.message);
                    }).catch((err) => { console.log(err) });
                }
            }, 500)
        }
    }, [name, defaultValue, apiname, colname, colvalue, list, user.access_token])

    //Lấy danh sách dữ liệu
    useEffect(() => {
        let mounted = true;
        if (!data) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'asselect': JSON.stringify([colvalue + " as value", colname + " as label"])
                }
            }
            
            Axios.get(URL_LIST_ALL + apiname + param + '&page=' + page, config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) {
                        let localList = []
                        localList.push({ value: "", label: placeholder })
                        if (all === true) localList.push({ value: "all", label: "Tất cả" })
                        localList = localList.concat(res.data.data)
                        setList(localList);
                        setTotalRecord(res.data.total);
                        // console.log(localList);
                        if (autoSelect === true && localList.length > 0 && onChange && !defaultValue) {
                            setValue(localList[0].value)
                            onChange(localList[0].value)
                        }
                        if (onDataList) onDataList(localList);
                    }
                } else console.log(res.data.message);
            }).catch((err) => { console.log(err) });
        }
        return () => (mounted = false);
    }, [user, apiname, data, colvalue, colname, all, param,page]);// eslint-disable-line

    const btSelect = async (data) => {
        setValue(data.value)
        setTitle(data.label)
        onChange(data.value)
        setShow(false)
    }

    const searchOnchange = (value) => {
        setTimeout(() => {
            setParam('?&key=' + value)
            setPage(1)
        }, 500)
    }

    return (
        <div className="search-box-modal height32">
            <div className="input-group mb-3">
                <input type="text" className="form-control height32" value={title} readOnly placeholder={title ? title : placeholder} aria-label="Username" aria-describedby="basic-addon1" />
                <input type="hidden" value={value ? value : ''} />
                {!disabled &&
                    <div className="input-group-prepend">
                        <button type="button" className="btn btn-sm btn-success height32" onClick={() => setShow(true)}>Chọn</button>
                    </div>
                }
            </div>

            <Modal show={show} onHide={() => setShow(false)} animation={true} size="md">

                <Modal.Header closeButton>
                    <Modal.Title>{placeholder}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {show &&
                        <div className="w-100 list-search-box" >
                            {
                                list ? <>
                                    <input type="text" className="form-control height32 mb-3" placeholder="Tìm kiếm" onChange={(e) => searchOnchange(e.target.value)} />
                                    <ul className="w-100 list-data">
                                        {list.map((item, i) => {
                                            return <li key={i} onClick={(e) => btSelect(item)}>{item.label}</li>
                                        })}
                                    </ul>
                                    <div className="w-100 pagination-group">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalRecord}
                                            pageRangeDisplayed={5}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            onChange={setPage}
                                        />
                                    </div>
                                </>
                                    : <h3>Không có dữ liệu</h3>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-secondary" onClick={() => setShow(false)}>Đóng</button>
                </Modal.Footer>

            </Modal>
        </div>);
}
//https://github.com/elrumordelaluz/reactour
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UploadButton from "../component/UploadButton";
import { checkRole } from "../model";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";
import file_icon from "../assets/img/file-icon.png"
import Moment from "react-moment";

export default function FormVanThu() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const submitRef = useRef(null);
    const role_approve = checkRole(user, 'van-thu', 'approve');
    const [checkerCode, setCheckerCode] = useState(null)

    const defaultdetail = {
        fullname: user ? user.data.fullname : '',
        part: user ? user.data.part_title : '',
        company: user ? user.data.company_title : '',
        user_code: user ? user.data.code : '',
        checker_code: detail && detail.checker_code ? detail.master_code : user.data.master_code,
        type: '',
        date_start: '',
        title: '',
        file_url: '',
        code: '',
        status: 0
    }

    const loaivanthu = {
        "NQ": "Nghị quyết",
        "QĐ":"Quyết định",
        "QC":"Quy chế",
        "QyĐ":"Quy định",
        "QT":"Quy trình",
        "HD":"Hướng dẫn",
        "CV":"Công văn",
        "TTr":"Tờ trình",
        "TB":"Thông báo",
        "BB":"Biên bản",
        "KH":"Kế hoạch",
        "HĐ":"Hợp đồng",
        "HĐLĐ":"Hợp đồng lao động",
        "HĐKV":"Hợp đồng khoán việc",
        "HĐDV":"Hợp đồng dịch vụ",
        "PL":"Phụ lục",
        "BM":"Biểu mẫu",
        "GUQ":"Giấy uỷ quyền",
        "GGT":"Giấy giới thiệu",
        "NQ":"Nội quy",
        "TU":"Thỏa ước",
        "NQu":"Nghị quyết",
        "BBH":"Biên bản họp HĐQT, HĐCĐ",
    }

    useEffect(function () {
        // console.log(role_edit);
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "vanthu/" + id, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    let data = res.data.data
                    if (data.file_url) {
                        data.file_url = JSON.parse(data.file_url)
                    }
                    setDetail(data);
                    setCheckerCode(data.checker_code ? data.checker_code : user.data.master_code)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        } else {
            Axios.get(URL_LIST_ALL + "users/" + user.data.id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        let info = res.data.data
                        setCheckerCode(info.checker_code ? info.checker_code : info.master_code)
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [user, id, refresh]);// eslint-disable-line

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Vui lòng nhập tiêu đề').max(300, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                if (values.status == 2 && values.file_url) {
                    values.status = 3
                }
            } else {
                values.status = 0;
            }

            if (id > 0) {
                if (detail.resion && detail.resion != values.resion) {
                    values.resion = user.data.fullname + ': ' + values.resion
                }
                if (detail.status == 5 && detail.user_code == user.data.code) {
                    values.status = 0
                    values.resion = null;
                }
                if (detail.status == 7 && detail.user_code == user.data.code) {
                    values.status = 3
                    values.resion = null;
                }
                if (detail.status == 6 && detail.user_code == user.data.code) {
                    values.status = 3
                    values.resion = null;
                }
                Axios.put(URL_LIST_ALL + 'vanthu/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        let message = '';
                        if (values.status == 0) {
                            message = 'Gửi lại thành công'
                        }
                        if (values.status == 1) {
                            message = 'Chấp thuận thành công'
                        }
                        if (values.status == 2) {
                            message = 'Bạn đã cấp số thành công'
                        }
                        if (values.status == 3) {
                            message = 'Bạn đã gửi file thành công, vui lòng đợi Chấp thuận'
                        }
                        if (values.status == 4) {
                            message = 'Bạn đã hoàn tất chu trình cấp số văn thư'
                        }
                        if (values.status == 5) {
                            message = 'Bạn đã từ chối thành công, chu trình sẽ thông báo và gửi lại cho ' + values.fullname + '-' + values.user_code
                        }
                        if (values.status == 6) {
                            message = 'Bạn đã từ chối thành công, chu trình sẽ thông báo và gửi lại cho ' + values.fullname + '-' + values.user_code
                        }
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: message,
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'vanthu', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn đăng ký lấy số thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/van-thu"
                                },
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleClick = useCallback(() => submitRef.current?.click(), []);

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="card height-auto">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title w-100 d-flex justify-content-between">
                            <h4>
                                {id ? "Cập nhập/Chấp thuận văn thư" : "Đăng ký lấy số"}
                            </h4>
                            <div className="">
                                {detail && detail.status == 7 && <span className="text-danger"><i className="far fa-pause-circle"></i>PHC đã từ chối duyệt file scan</span>}
                                {detail && detail.status == 6 && <span className="text-danger"><i className="far fa-pause-circle"></i>PHC đã từ chối cấp số</span>}
                                {detail && detail.status == 5 && <span className="text-danger"><i className="fas fa-exclamation-circle"></i>Quản lý từ chối duyệt</span>}
                                {detail && detail.status == 4 && <span className="text-success"><i className="fab fa-centos"></i> Hoàn tất việc cấp số</span>}
                                {detail && detail.status == 3 && <span className="text-success"><i className="fab fa-centos"></i> Đã gửi file chờ xác nhận</span>}
                                {detail && detail.status == 2 && <span className="text-success"><i className="fab fa-centos"></i> Đã cấp số chờ gửi file Scan</span>}
                                {detail && detail.status == 1 && <span className="text-success"><i className="fab fa-centos"></i> Quản lý đã duyệt, chờ cấp số</span>}
                                {detail && detail.status == 0 && <span className="text-warning"><i className="fab fa-stumbleupon-circle"></i> Đã gửi chờ Chấp thuận</span>}
                            </div>
                        </div>
                    </div>

                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 form-group ">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" placeholder="Tiêu đề" className="form-control height32" autoComplete="off" {...formik.getFieldProps("title")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-3 mt-2">
                                <label>Họ và tên</label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 mt-2">
                                <label>Mã nhân viên</label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("user_code")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 mt-2">
                                <label>Phòng ban </label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("part")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 mt-2">
                                <label>Công ty </label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("company")} autoComplete="off" />
                            </div>
                            <div className="col-12"></div>
                            {id > 0 || (detail && user.data.code != detail.user_code)
                                ?
                                <div className="col-6 mt-2">
                                    <label>Cán bộ quản lý </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("checker_name")} autoComplete="off" disabled />
                                </div>
                                :
                                <div className="col-6 mt-2">
                                    <label>Cán bộ quản lý </label>
                                    <SearchBoxModal apiname={"users"} placeholder="Chọn quản lý trực tiếp" colvalue={'code'} colname={'fullname'} defaultValue={detail ? detail.checker_code : user.data.master_code} name='checker_code' disabled={!role_approve}
                                        onChange={async (e) => {
                                            await formik.setFieldValue('checker_code', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="checker_code"></AlertErrorForm>
                                </div>
                            }

                            <div className="col-sm-3 col-12 mt-2">
                                <label>Loại văn thư</label>
                                <select className="form-select height32" {...formik.getFieldProps("type")} disabled={detail && user.data.code == detail.user_code &&  detail.status != 0 ? true : false}>
                                    <option value="">Chọn loại văn thư</option>
                                    {Object.keys(loaivanthu).map((key,i) => {
                                        return <option value={key} key={i}>{loaivanthu[key]} - {key}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-sm-3 col-12 mt-2 form-group ">
                                <label>Người ký</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ndvt")} autoComplete="off" />
                            </div>
                            <div className="col-sm-6 col-12 mt-2">
                                <label>Trạng thái văn thư</label>
                                <select className="form-select height32" {...formik.getFieldProps("type_vt")} disabled={detail && user.data.code == detail.user_code && detail.status != 0 ? true : false}>
                                    <option value="">Chọn loại trạng thái văn thư</option>
                                    <option value="pubic">Công khai</option>
                                    <option value="private">Riêng tư</option>
                                </select>
                            </div>
                            {detail && detail.code && <div className="col-12 col-sm-6 mt-2">
                                <label>Số văn thư</label>
                                <h4>{detail.code}</h4>
                            </div>}

                            {id > 0 && detail && detail.status > 1 ?
                                <div className="col-12 mt-2">
                                    <label>File scan đính kèm</label>
                                    {detail.status != 4 && <input type="hidden" placeholder="" className="form-control height32 mb-2" {...formik.getFieldProps("file_url")} autoComplete="off" disabled />}
                                    <div className="row">
                                        {formik.values['file_url'].length > 0 &&
                                            formik.values['file_url'].map((itemFile, key) => {
                                                return <div className="col-12 col-sm-3 col-lg-2 mb-3" key={key}>
                                                    <div className="w-100 wrapper border rounded">
                                                        <a href={itemFile} target="_blank">
                                                            <img src={file_icon} alt='' className="w-100" />
                                                        </a>
                                                        <p className="w-100 text-center">{itemFile.length > 25 ? '...' + itemFile.slice(-25) : itemFile}</p>
                                                        {detail.status != 4 && <button type="button" className="btn btn-sm btn-danger w-100" onClick={() => {
                                                            var list = [...formik.values['file_url']]
                                                            const index = list.indexOf(itemFile);
                                                            if (index > -1) { // only splice array when item is found
                                                                list.splice(index, 1); // 2nd parameter means remove one item only
                                                            }
                                                            formik.setFieldValue('file_url', list)
                                                        }}>Xóa</button>}
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>

                                    {detail.status == 2 || detail.status == 7 && <UploadButton defaultValue="" button_title="Tải lên" onSellect={(val) => { formik.setFieldValue('file_url', val) }} multiple={true} onlytype=".pdf" />}
                                </div>
                                : ''
                            }

                            <div className="col-12"></div>
                            {id > 0 && detail &&
                                <>{detail.user_code != user.data.code ?
                                    <>
                                        {detail.status == 0 && detail.checker_code == user.data.code &&
                                            <div className="w-100 d-flex justify-content-center form-group mt-5">
                                                <div className="col-12 col-sm-6">
                                                    <label className="w-100 text-center">Thao tác</label>
                                                    <div className="w-100 d-flex justify-content-center">
                                                        <button type="button" className="w-100 btn btn-sm btn-success"
                                                            onClick={() => {
                                                                formik.setFieldValue('status', 1)
                                                                handleClick()
                                                            }}>
                                                            Chấp thuận
                                                        </button>
                                                    </div>
                                                    <div className="w-100 d-flex justify-content-center mt-3">
                                                        <input type="text" placeholder="Nhập lý do từ chối" className="form-control height32" autoComplete="off" {...formik.getFieldProps("resion")} />
                                                        <button type="button" className="btn btn-sm btn-danger"
                                                            onClick={() => {
                                                                formik.setFieldValue('status', 5)
                                                                handleClick()
                                                            }}
                                                        >Từ chối duyệt</button>

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 1 && detail.aprovecode == user.data.code &&
                                            <div className="w-100 d-flex justify-content-center form-group mt-5">
                                                <div className="col-12 col-sm-6">
                                                    <label className="w-100 text-center">Thao tác</label>
                                                    <div className="w-100 d-flex justify-content-center">
                                                        <input type="text" placeholder="Nhập mã văn thư" className="form-control height32" autoComplete="off" {...formik.getFieldProps("code")} />

                                                        <button type="button" className="btn btn-sm btn-success"
                                                            onClick={() => {
                                                                formik.setFieldValue('status', 2)
                                                                handleClick()
                                                            }}>
                                                            Chấp thuận
                                                        </button>
                                                    </div>
                                                    <div className="w-100 d-flex justify-content-center mt-3">
                                                        <input type="text" placeholder="Nhập lý do từ chối" className="form-control height32" autoComplete="off" {...formik.getFieldProps("resion")} />
                                                        <button type="button" className="btn btn-sm btn-danger"
                                                            onClick={() => {
                                                                formik.setFieldValue('status', 6)
                                                                handleClick()
                                                            }}
                                                        >Từ chối duyệt</button>

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 3 && detail.aprovecode == user.data.code &&
                                            <div className="col-12 form-group mt-5">
                                                <label className="w-100 text-center">Thao tác</label>
                                                <div className="w-100 d-flex justify-content-center">
                                                    <div className="col-12 col-sm-6">
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <button type="button" className="w-100 btn btn-sm btn-success ml-2"
                                                                onClick={() => {
                                                                    formik.setFieldValue('status', 4)
                                                                    handleClick()
                                                                }}
                                                            >
                                                                <h4 className="text-white">Chấp thuận</h4>
                                                            </button>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-center mt-3">
                                                            <button type="button" className="btn btn-sm btn-danger ml-2"
                                                                onClick={() => {
                                                                    formik.setFieldValue('status', 7)
                                                                    handleClick()
                                                                }}
                                                            >Từ chối</button>
                                                            <input type="text" placeholder="Nhập lý do từ chối" className="form-control height32" autoComplete="off" {...formik.getFieldProps("resion")} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                        {detail.status == 4 &&
                                            <div className="w-100 d-flex justify-content-center mt-3">
                                                <div className="col-12 col-sm-3 bg-success text-center text-white py-2">Văn thư đã được Chấp thuận</div>
                                            </div>
                                        }
                                        {detail.status == 5 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.checker_name} </b>
                                                    <span className="ml-1 mr-2 text-danger">đã từ chối duyệt.</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                        {detail.status == 6 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.aprove_name} </b>
                                                    <span className="ml-1 mr-2 text-danger">đã từ chối duyệt.</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                        {detail.status == 7 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.aprove_name} </b>
                                                    <span className="ml-1 mr-2 text-danger"> đã từ chối duyệt file scan</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {detail.status == 1 &&
                                            <div className="col-12 form-group mt-5 ">
                                                <div className="w-100 d-flex justify-content-center">
                                                    <div className="col-12 col-sm-6 py-2 text-center text-white bg-success">
                                                        <b>{detail.checker_name}</b> đã Chấp thuận văn thư này
                                                    </div>
                                                </div>
                                                <div className="w-100 text-center text-danger mt-2">
                                                    Vui lòng cung cấp bản cứng cho Phòng hành chính và đợi cấp số
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 2 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.aprove_name} </b>
                                                    <span className="ml-1 mr-2">đã cấp số cho văn thư với mã số là: </span>
                                                    <h4>{detail.code}</h4>
                                                </div>
                                                <div className="w-100 text-center text-danger mt-2">
                                                    Vui lòng cung cấp bản scan và upload lên
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 5 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.checker_name} </b>
                                                    <span className="ml-1 mr-2 text-danger">đã từ chối duyệt.</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                    </>
                                }
                                </>
                            }

                            <div className="col-12 mt-3"></div>

                            <div className="col-12"></div>
                            <div className={"col-12 d-flex justify-content-center form-group mg-t-8 " + ((detail && detail.user_code == user.data.code) || id == 0 || id == null ? "" : 'd-none')}>
                                {detail && detail.status == 4 ?
                                    <div className="w-100 d-flex justify-content-center mt-3">
                                        <div className="col-12 col-sm-3 bg-success text-center text-white py-2">Văn thư đã được Chấp thuận</div>
                                    </div>
                                    :
                                    <button type="submit" disabled={formik.isSubmitting} ref={submitRef} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> :
                                        <>
                                            {id > 0 ? "Lưu lại" : 'Đăng ký'}
                                        </>
                                    }</button>
                                }
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}

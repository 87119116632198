
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

export default function DanhSachGiaiTrinh() {
  const [params, setParam] = useState('');
  const [userCode, setUserCode] = useState();
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [fullname, setFullname] = useState();
  const [filterShow, setFilterShow] = useState(false)
  const [type, setType] = useState(null)
  const [status, setStatus] = useState(null)
  const [dateCheck, setDateCheck] = useState(null)
  const [dateSelect, setDateSelect] = useState({datecheck:null})


  const handleDate = (name, value) => {
 
    setDateCheck( moment(value).format('YYYY-MM-DD'))
    let newDate = Object.assign({}, dateSelect)
    newDate[name] = value
    setDateSelect(newDate)
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (fullname && fullname !== '') _param += "&fullname=" + fullname
    if (userCode && userCode !== '') _param += "&user_code=" + userCode
    if (type && type !== '') _param += "&type=" + type
    if (status && status !== '') _param += "&status=" + status
    if (dateCheck && dateCheck !== '') _param += "&datestart=" + dateCheck
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }


  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ tên" },
    { name: "user_code", label: "MSNV" },
    { name: "part", label: "Phòng ban" },
    { name: "type", label: "Lý do" },
    { name: "reason", label: "Chi tiết" },
    { name: "time_break", label: "Thời gian" },
    { name: "time_gt", label: "Số phút" },
    { name: "date_start", label: "Ngày chấm công" },
    { name: "datesend", label: "Thời gian gửi" },
    { name: "checker_name", label: "Người duyệt" },
    { name: "check_date", label: "Thời gian phê duyệt" },
    { name: "status", label: "Trạng thái" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Danh sách giải trình chấm công
        </li>
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={() => setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>

        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Họ tên nhân viên </label>
              <input type="text" placeholder="Họ tên nhân viên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã số nhân viên </label>
              <input type="text" placeholder="Tìm mã số nhân viên" value={userCode} onChange={(e) => setUserCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-xl-2 col-lg-6 col-12 form-group">
              <label>Ngày giải trình</label>
              <DateTimePicker onChange={(value) => { handleDate('datecheck', value) }} value={dateSelect.datecheck ? new Date(dateSelect.datecheck) : null} maxDate={new Date()} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
            </div>

            <div className="col-lg-2 col-12 form-group">
              <label>Lý do</label>
              <select className="form-select height32" onChange={(e) => setType(e.target.value)}>
                <option value="">Chọn lý do</option>
                <option value="Không chấm công">Không chấm công</option>
                <option value="Đi muộn">Đi muộn</option>
                <option value="Về sớm">Về sớm</option>
                <option value="Không chấm công vào">Không chấm công vào</option>
                <option value="Không chấm công ra">Không chấm công ra</option>
              </select>
            </div>

            <div className="col-lg-2 col-12 form-group">
              <label>Trạng thái</label>
              <select className="form-select height32" onChange={(e) => setStatus(e.target.value)}>
                <option value="">Chọn trạng thái</option>
                <option value="4">Chờ gửi</option>
                <option value="0">Chờ duyệt</option>
                <option value="1">Đã duyệt</option>
                <option value="2">Từ chối duyệt</option>
                <option value="3">Đang xem xét</option>
              </select>
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="giaitrinh" linkaction="giaitrinh" idrole="list-giaitrinh" params={params} title="Danh sách đăng ký giải trình công" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}


import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import AlertErrorForm from "../component/AlertErrorForm";
import UploadButton from "../component/UploadButton";
import { checkRole } from "../model";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormGiaiTrinh() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [dateSelect, setDateSelect] = useState({ date_start: null });
    const [timeBreak, setTimeBreak] = useState('');
    const allowEditchecker = checkRole(user, 'list-giaitrinh', 'editcheck');

    const defaultdetail = { status: 0, fullname: user.data && user.data.fullname ? user.data.fullname : '', part: user.data && user.data.part_title ? user.data.part_title : '', company: user ? user.data.company_title : '', user_code: user.data && user.data.code ? user.data.code : '', type: 'Nghỉ phép', date_start: '', list_checker: '', images: '' }

    useEffect(function () {
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "giaitrinh/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        let data_detail = res.data.data
                        const date_start = data_detail.date_start ? data_detail.date_start : ''
                        const time_break = data_detail.time_break ? data_detail.time_break + ' ' : ''
                        let reason = data_detail.reason
                        if (!reason) data_detail.reason = ''
                        setDetail(data_detail);
                        setTimeBreak(time_break + moment(date_start).format('DD/MM/YYYY'))
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [user, id]);

    useEffect(function () {
        if (detail) {
            let mounted = true;
            if (mounted) {
                const date_start = detail.date_start
                let newDate = Object.assign({}, dateSelect)
                newDate['date_start'] = date_start
                setDateSelect(newDate)
                return () => (mounted = false);
            }

        }
    }, [detail]);

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({

            // reason: Yup.string().trim().nullable().required('Vui lòng nhập lý do').max(300, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            values.status = 0
            setLoading(true)
            if (id > 0) {
                if (user.data.code !== values.user_code) {
                    values.status = detail.status
                }
                var err = false
                if (values.type == 'Không chấm công vào' || values.type == "Không chấm công ra") {
                    if (values.images == '' || !values.images || values.images == null) {
                        alert("Vui lòng upload ảnh giải trình");
                        err = true
                    }
                }
                if (!err) {
                    Axios.put(URL_LIST_ALL + 'giaitrinh/' + id, values, user.config).then(res => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {
                            resetForm();
                            confirmAlert({
                                title: 'Thành công',
                                message: 'Bạn cập nhật giải trình thành công',
                                buttons: [{
                                    label: 'OK', onClick: () => {
                                        window.location.reload()
                                    },
                                }
                                ]
                            });
                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                }
            } else {

                Axios.post(URL_LIST_ALL + 'giaitrinh', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn giải trình thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-giaitrinh">Danh sách giải trình</Link>
                    </li>
                    <li>{id ? 'Cập nhập giải trình' : 'Thêm mới giải trình'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Giải trình</h3>
                        </div>
                    </div>
                    {(id > 0 && detail && (detail.status !== 4 && user.data.id != 1)) ?
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <table className="table table-borderless table-infomation">
                                    <tbody>
                                        {
                                            detail && <>
                                                <tr>
                                                    <td><b>Họ và tên:</b> </td>
                                                    <td>{detail.fullname ? detail.fullname : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Mã số nhân viên:</b> </td>
                                                    <td>{detail.user_code ? detail.user_code : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Phòng ban:</b> </td>
                                                    <td>{detail.part ? detail.part : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Công ty:</b> </td>
                                                    <td>{detail.company ? detail.company : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Đăng ký:</b> </td>
                                                    <td>{detail.type ? detail.type : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Lý do:</b> </td>
                                                    <td>{detail.reason ? detail.reason : ''}</td>
                                                </tr>
                                                {detail.images &&
                                                    <tr>
                                                        <td><b>Ảnh đính kèm:</b> </td>
                                                        <td><img src={detail.images} alt='' className="w-100" /></td>
                                                    </tr>
                                                }

                                            </>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 mt-3"></div>

                            <div className="col-sm-4 col-12">
                                <label className="w-100"><b>{detail && detail.checker_name}</b></label>
                                {detail.status === 0 && <span className="text-warning">Chờ duyệt</span>}
                                {detail.status === 1 && <span className="text-success">Đã phê duyệt</span>}
                                {detail.status === 2 && <span className="text-danger">Không phê duyệt</span>}
                                {detail.status === 3 && <span className="text-info">Đang xem xét</span>}
                                {detail.status === 4 && <span className="text-info">Chờ gửi</span>}
                                {detail.comment && <div className="w-100 text-left mt-2">
                                    <b>Ý kiến:</b> {detail.comment}
                                </div>}
                                {detail.status === 5 && <h3 className="text-danger">Hết hạn giải trình</h3>}
                            </div>
                            <div className="w-100"></div>


                        </div>
                        :
                        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Họ và tên <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                </div>
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Mã số nhân viên <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("user_code")} autoComplete="off" />
                                </div>
                                <div className="col-12"></div>
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Phòng ban </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("part")} autoComplete="off" />
                                </div>

                                <div className="col-sm-4 col-12 form-group">
                                    <label>Công ty </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("company")} autoComplete="off" />
                                </div>
                                <div className="col-12"></div>
                                <div className="col-sm-2 col-12 form-group">
                                    <label>Thời gian: </label>
                                    <input type="text" placeholder="" readOnly={user.data.id !== 1 ? true : false} className="form-control height32" {...formik.getFieldProps("time_break")} autoComplete="off" />
                                </div>
                                <div className="col-sm-2 col-12 form-group">
                                    <label>Ngày: </label>
                                    <input type="text" placeholder="" readOnly={user.data.id !== 1 ? true : false} className="form-control height32" {...formik.getFieldProps("date_start")} autoComplete="off" />
                                </div>

                                <div className="col-lg-4 col-12 form-group">
                                    <label>Lý do</label>
                                    {id > 0 && user.data.id !== 1 ?
                                        <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("type")} autoComplete="off" />
                                        :
                                        <select className="form-select height32" {...formik.getFieldProps("type")} >
                                            <option value="Nghỉ không phép">Nghỉ không phép</option>
                                            <option value="Đi muộn">Đi muộn</option>
                                            <option value="Về sớm">Về sớm</option>
                                            <option value="Không chấm công vào">Không chấm công vào</option>
                                            <option value="Không chấm công ra">Không chấm công ra</option>
                                        </select>
                                    }

                                </div>
                                <div className="col-12"></div>
                                <div className="col-lg-8 col-12 form-group">
                                    <label>Lý do chi tiết</label>
                                    <textarea className="form-control" {...formik.getFieldProps("reason")}></textarea>
                                    <AlertErrorForm formik={formik} name="reason"></AlertErrorForm>
                                </div>
                                {id > 0 && <>
                                    {detail && user.data.code === detail.user_code && detail && (detail.type === 'Không chấm công vào' || detail.type === 'Không chấm công ra') &&
                                        <div className="col-lg-8 col-12 form-group">
                                            <label>Hình ảnh chứng minh</label>
                                            <input type="hidden" placeholder="" className="form-control height32 mb-2" {...formik.getFieldProps("images")} autoComplete="off" disabled />
                                            <div className="row">
                                                {formik.values['images'] && formik.values['images'] !== '' &&
                                                    <div className="col-12 col-sm-4 mb-3">
                                                        <img src={formik.values['images']} alt='' className="w-100" />
                                                        <button type="button" className="btn btn-sm btn-danger w-100" onClick={() => { formik.setFieldValue('images', '') }}>Xóa</button>
                                                    </div>
                                                }
                                            </div>
                                            <UploadButton defaultValue="" button_title="Tải lên" onSellect={(val) => { formik.setFieldValue('images', val) }} onlytype="image" />
                                        </div>
                                    }
                                </>
                                }
                                <div className="col-12"></div>

                                {detail && <>
                                    {allowEditchecker ?
                                        <div className="col-sm-6 col-12 form-group">
                                            <label>Sửa Người duyệt đơn </label>
                                            <SearchBoxModal apiname={"users"} placeholder="Chọn nhân sự" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['checker_code']} name='checker_code'
                                                onChange={async (e) => {
                                                    await formik.setFieldValue('checker_code', e)
                                                }}></SearchBoxModal>
                                            <button type="submit" disabled={formik.isSubmitting} className="btn btn-info mt-3">{loading ? <Spinner /> : <>Cập nhập</>}</button>
                                        </div>
                                        :
                                        detail && detail.checker_name &&
                                        <div className="col-lg-8 col-12 form-group">
                                            <label>Người phê duyệt: </label>
                                            <span><b>{detail.checker_name}</b> <small>({detail.checker_chucvu})</small></span>
                                        </div>}
                                </>
                                }
                                <div className="col-12 form-group mg-t-8">
                                    {id > 0 ? <>
                                        {detail && user.data.code === detail.user_code &&
                                            <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Gửi giải trình</>}</button>
                                        }
                                    </> :
                                        <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Giải trình</>}</button>
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </AkkhorLayout>
    );
}


import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default function DanhSachXinNghi() {
  const [params, setParam] = useState();
  const [userCode, setUserCode] = useState();
  const [fullname, setFullname] = useState();
  const [filterShow, setFilterShow] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [type, setType] = useState(null)
  const [status, setStatus] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (userCode && userCode !== '') _param += "&user_code=" + userCode
    if (fullname && fullname !== '') _param += "&fullname=" + fullname
    if (type && type !== '') _param += "&type=" + type
    if (status && status !== '') _param += "&status=" + status
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ tên" },
    { name: "user_code", label: "Mã nhân viên" },
    { name: "part", label: "Phòng ban" },
    { name: "type", label: "Phân loại" },
    { name: "reason", label: "Lý do" },
    { name: "number", label: "Số lượng" },
    { name: "date_start", label: "Thời gian bắt đầu" },
    { name: "date_end", label: "Thời gian kết thúc" },
    { name: "checker_name", label: "Người duyệt" },
    { name: "status", label: "Trạng thái" },
    { name: "check_date", label: "Thời gian phê duyệt" },
    { name: "datecreate", label: "Ngày tạo" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
        <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              Danh sách xin nghỉ, đi muộn, về sớm, công tác
            </li>
        </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={()=>setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Họ tên </label>
              <input type="text" placeholder="Họ tên nhân viên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã số </label>
              <input type="text" placeholder="Tìm mã số nhân viên" value={userCode} onChange={(e) => setUserCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-md-4 col-12 form-group">
              <label>Khoảng thời gian xin nghỉ</label>
              <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: new Date()}}onCallback={handleCallback}>
                <div className='d-flex align-items-center'>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setStartDate(e)}
                    value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                  />
                  <span className='fas fa-arrow-right mx-4' />   
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setEndDate(e)}
                    value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                  />
                </div>
              </DateRangePicker>
            </div>
           
            <div className="col-lg-2 col-12 form-group">
              <label>Loại đăng ký</label>
              <select className="form-select height32" onChange={(e)=>setType(e.target.value)}>
                  <option value="">Chọn loại đăng ký</option>
                  <option value="Nghỉ phép">Nghỉ phép</option>
                  <option value="Nghỉ bù">Nghỉ bù</option>
                  <option value="Nghỉ không lương">Nghỉ không lương</option>
                  <option value="Đi muộn">Đăng ký đi muộn</option>
                  <option value="Về sớm">Đăng ký về sớm</option>
                  <option value="Làm việc ngoài văn phòng">Đăng ký làm việc ngoài văn phòng</option>
                  <option value="Công tác">Đăng ký công tác</option>
                  <option value="Nghỉ chế độ">Nghỉ chế độ</option>
              </select>
            </div>

            <div className="col-lg-2 col-12 form-group">
              <label>Trạng thái</label>
              <select className="form-select height32" onChange={(e)=>setStatus(e.target.value)}>
                <option value="">Chọn trạng thái</option>
                <option value="4">Chờ gửi</option>
                <option value="0">Chờ duyệt</option>
                <option value="1">Đã duyệt</option>
                <option value="2">Từ chối duyệt</option>
                <option value="3">Đang xem xét</option>
              </select>
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="users_break" linkaction="users-break" idrole="list-users-break" params={params} title="Xin nghỉ/Đi muộn/về sớm" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}

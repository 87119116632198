
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Moment from "react-moment";

export default function FormDuyetGiaiTrinh() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = { status: 1, date_start: '', checker: '' }

    useEffect(function () {
        // console.log(user.data);
        if (id > 0) {
            let mounted = true;
            Axios.get(URL_LIST_ALL + "giaitrinh/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (mounted) {
                            const data = res.data.data
                            setDetail(data);
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);
        }
    }, [user, id, refresh]);

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'duyet_giaitrinh/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn phê duyệt thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/list-approve-break"
                                },
                            }
                            ]
                        });

                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="list-duyet-giai-trinh">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-duyet-giai-trinh">Danh sách phê duyệt giải trình</Link>
                    </li>
                    <li>Phê duyệt giải trình</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Phê duyệt giải trình</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <table className="table table-borderless">
                                    <tbody>
                                        {
                                            detail && <>
                                                <tr>
                                                    <td><b>Họ và tên:</b> </td>
                                                    <td>{detail.fullname ? detail.fullname : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Mã số nhân viên:</b> </td>
                                                    <td>{detail.user_code ? detail.user_code : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Phòng ban:</b> </td>
                                                    <td>{detail.part ? detail.part : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Công ty:</b> </td>
                                                    <td>{detail.company ? detail.company : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Lý do:</b> </td>
                                                    <td>{detail.type ? detail.type : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Ngày:</b> </td>
                                                    <td>{detail && detail.date_start ? <Moment format="DD-MM-YYYY">{detail.date_start}</Moment> : ''}</td>
                                                </tr>
                                                {detail && detail.time_break &&
                                                    <tr>
                                                        <td><b>Thời gian:</b> </td>
                                                        <td>{detail.time_break}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td><b>Chi tiết:</b> </td>
                                                    <td>{detail.reason ? detail.reason : ''}</td>
                                                </tr>
                                                {detail.images &&
                                                    <tr>
                                                        <td><b>Ảnh đính kèm:</b> </td>
                                                        <td><img src={detail.images} alt='' className="w-100"/></td>
                                                    </tr>
                                                }
                                            </>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 mt-3"></div>
                            {user && detail &&
                                <>
                                    {detail.checker_code === user.data.code && detail.status === 0 ?
                                        <div className="col-auto form-group text-center">
                                            <label>Xác nhận từ bạn </label>
                                            <select className="form-select height32" {...formik.getFieldProps("status")}>
                                                <option value="0">Chờ phê duyệt</option>
                                                <option value="1">Phê duyệt</option>
                                                <option value="2">Không phê duyệt</option>
                                                <option value="3">Xem xét</option>
                                            </select>
                                            <textarea className="form-control mt-2" {...formik.getFieldProps("checker_comment")} placeholder="Bình luận"></textarea>
                                        </div>
                                        :
                                        <div className="col-auto form-group text-center">
                                            <h5 className="w-100 m-0"><b>{detail.checker_name}</b></h5>
                                            {detail.checker_chucvu && <small className="mb-2">({detail.checker_chucvu})</small>}<br />
                                            {detail.status === 0 && <span className="text-warning">Chờ duyệt</span>}
                                            {detail.status === 1 && <span className="text-success">Đã phê duyệt</span>}
                                            {detail.status === 2 && <span className="text-danger">Không phê duyệt</span>}
                                            {detail.status === 3 && <span className="text-warning">Đang xem xét</span>}
                                            {detail.comment &&
                                                <div className="w-100 text-left mt-2">
                                                    <b>Ý kiến:</b> {detail.comment}
                                                </div>
                                            }
                                        </div>
                                    }

                                    {detail.status === 0 &&
                                        <div className="col-12 form-group mg-t-8">
                                            <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Cập nhập</>}</button>
                                        </div>
                                    }
                                </>}
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}

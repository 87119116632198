
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function DanhSachLog() {
  const [params, setParam] = useState('');
  const [tablename, setTablename] = useState();
  const [tableId, setTableId] = useState();
  const [filterShow, setFilterShow] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (tablename && tablename !== '') _param += "&tablename=" + tablename
    if (tableId && tableId !== '') _param += "&table_id=" + tableId

    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ tên" },
    { name: "tablename", label: "Tên bảng" },
    { name: "table_id", label: "ID bảng" },
    { name: "action", label: "Thao tác" },
    { name: "olddata", label: "Dữ liệu cũ" },
    { name: "newdata", label: "Dữ liệu mới" },
    { name: "chuoikhac", label: "Dữ liệu thay đổi" },
    { name: "datecreate", label: "Thời gian" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Logs hệ thống
        </li>
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={() => setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>

        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên bảng </label>
              <input type="text" placeholder="Tìm tên bảng" value={tablename} onChange={(e) => setTablename(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>ID bảng </label>
              <input type="text" placeholder="ID bảng" value={tableId} onChange={(e) => setTableId(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="logs" linkaction="logs" idrole="logs" params={params} title="Danh sách logs" defaultLimit={50} serverSide={true} columns={columns} >

    </SRList>
  </AkkhorLayout>);
}

import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../redux/authRedux";
import { resetRedux, saveRefreshSocket, setResetComments, setResetPost } from "../redux/Actions";
import onClickOutside from "react-onclickoutside";
import noibo from "../assets/img/bhs-noibo-logo.png";
import noiboicon from "../assets/img/bhs-noibo.png";
// import Pusher from "pusher-js";
import Axios from "axios";
import parse from 'html-react-parser'
import Avatar from "../assets/img/figure/admin.jpg";
import { URL_LIST_ALL } from "../configs/api";
import { jwtDecode } from "jwt-decode";
import wavFile from "../assets/notification.wav"

function HeaderBar(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showMobile, setShowMobile] = useState(false);
  const [show, setShow] = useState(false);
  const info = user && user.data ? user.data : null;
  const [uAvatar, setUAvatar] = useState(null)
  const [socket, setSocket] = useState(null);
  const [showNoti, setShowNoti] = useState(false);
  const [refreshNoti, setRefreshNoti] = useState(false);
  const [listNotification, setListNotification] = useState(null);
  const [countNoti, setCountNoti] = useState(0);
  const [soundPlay, setSoundPlay] = useState(false);
  const [dataNotifi, setDataNotifi] = useState(null);

  //Hàm xử lý đăng xuất
  const dologout = () => {
    confirmAlert({
      title: "Đăng xuất",
      message: "Bạn có chắc muốn đăng xuất",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Đăng xuất",
          onClick: () => {
            dispatch(resetRedux());
            dispatch(actions.login(null));
          },
        },
        { label: "Không" },
      ],
    });
  };
  HeaderBar["handleClickOutside_header"] = () => {
    setShow(false);
    setShowNoti(false)
  }

  useEffect(function () {
    if (user) {
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.access_token,
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }
      Axios.get(URL_LIST_ALL + "users_avatar?limit=1&user_code=" + user.data.code, config).then((res) => {
        if (res.data.status === "success") {

          if (res.data.data && res.data.data[0]) {
            setUAvatar(res.data.data[0]['url'])
          }
        } else alert(res.data.message)
      }).catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    const connectWebSocket = () => {
      // setFlag(true)
      const socketInstance = new WebSocket('wss://socket.bhsgroup.vn');
      socketInstance.onopen = () => { };
      socketInstance.onmessage = (evt) => {
        if (evt.data) {
          let data = evt.data
          try {
            data = jwtDecode(data)
            if (data) {
              if (data.channel && data.channel == 'bhs_socket') {
                if (data.data.to_user_code === user.data.code) {
                  if (data.event == 'notifications' && data.data.to_user_code == user.data.code) {
                    setRefreshNoti(data.data)
                    if (data.data.node_type != dataNotifi) setDataNotifi(data.data.node_type)
                  }
                  dispatch(saveRefreshSocket(data));
                  setSoundPlay(true)
                  if (data.event && data.event == 'post' + user.data.id) {
                    dispatch(setResetPost(data.data));
                  }
                  if (data.event && data.event == 'comments' + user.data.id) {
                    dispatch(setResetComments(data.data));
                  }
                }
              }
            }
          } catch (error) {
          }
        }
      };
      socketInstance.onclose = (event) => {
        setTimeout(() => {
          connectWebSocket();
        }, 1000);
      };
      setSocket(socketInstance);
    };
    if (!socket) {
      connectWebSocket();
    }
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  const markSeen = (id, href = null) => {
    Axios.put(URL_LIST_ALL + "notifications/" + id, { seen: 1 }, user.config).then(async (res) => {
      if (href) {
        window.location.href = href
      } else {
        setRefreshNoti(!refreshNoti)
      }
    })
  }
  const markSeenAll = (id, href = null) => {
    Axios.post(URL_LIST_ALL + "seenall", {}, user.config).then(async (res) => {
      if (href) {
        window.location.href = href
      } else {
        document.title = 'BHS - Nội bộ'
        setRefreshNoti(!refreshNoti)
      }
    })
  }
  useEffect(() => {
    if (user) {
      Axios.get(URL_LIST_ALL + "notifications?limit=30&to_user_code=" + user.data.code, user.config).then(async (res) => {
        if (res.data.status === 'success') {
          let datars = res.data.data
          setListNotification(datars)

          let count_no_seen = 0
          datars.forEach((item) => {
            if (item.seen == 0 || !item.seen) {
              count_no_seen += 1;
            }
          });
          // console.log(count_no_seen);
          if (count_no_seen > 99) {
            count_no_seen = 99
          }
          if (count_no_seen > 0) document.title = 'BHS - Nội bộ (' + count_no_seen + ' thông báo)';
          setCountNoti(count_no_seen)

        } else {
          alert(res.data.message)
        }
      }).catch((err) => { console.log(err); });
    }
  }, [user, refreshNoti])

  useEffect(() => {
    if (soundPlay) {
      try {
        const audio = new Audio(wavFile);
        audio.play()
      } catch (error) {

      }
      setSoundPlay(false)
    }
  }, [soundPlay]);
  useEffect(() => {
    if (dataNotifi) {
      showNotification(dataNotifi)
    }
  }, [dataNotifi]);

  const showNotification = (message) => {
    const regex = /(<([^>]+)>)/gi;
    message = message.replace(regex, "");
    // Kiểm tra xem Notifications API có được hỗ trợ không
    if (!("Notification" in window)) {
      console.log("Trình duyệt của bạn không hỗ trợ Notifications API.");
      return;
    }

    // Yêu cầu quyền sử dụng Notifications API
    if (Notification.permission === "granted") {
      // Tạo thông báo nếu đã được cấp quyền
      new Notification("BHS - Thông báo", {
        body: message,
        icon: noiboicon // Có thể bỏ qua hoặc thêm đường dẫn đến icon
      });
    } else if (Notification.permission !== "denied") {
      // Nếu chưa yêu cầu quyền, yêu cầu người dùng cấp quyền
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          new Notification("BHS - Thông báo", {
            body: message,
            icon: noiboicon
          });
        }
      });
    }
  }

  return (
    <>
      {/* Header Menu Area Start Here */}
      <div className="navbar navbar-expand-md header-menu-one bg-light">
        <div className="nav-bar-header-one">
          <div className="header-logo">
            <Link to="/dashboard">
              <img src={noibo} alt="logo" />
            </Link>
          </div>
          <div className="toggle-button sidebar-toggle">
            <button type="button" className="item-link">
              <span className="btn-icon-wrap">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
        </div>
        <div className="d-md-none mobile-nav-bar">
          <div className={"navbar-item notification-bar dropdown " + (showNoti ? 'show' : '')}>
            <a className="navbar-nav-link dropdown-toggle"
              href="#shool"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
              onClick={() => setShowNoti(!showNoti)}
            >
              <i className="fas fa-bell"></i>
              {countNoti > 0 && <span className="count-number">{countNoti}</span>}
            </a>
            <div className={`dropdown-menu dropdown-menu-right ${showNoti ? "show" : ""}`} >
              <div className="w-100 d-flex justify-content-end border-bottom">
                <button className="btn readall" type="button" onClick={() => markSeenAll()}>Đánh dấu đọc tất cả</button>
              </div>
              {listNotification ? listNotification.map((item, i) => {
                return (<div className={"notification-item p-2 " + (item.seen === 1 ? "seen" : '')} key={i}>
                  {item.avatar ?
                    <img src={item.avatar} alt="" className="avatar" />
                    :
                    ''
                  }
                  <div className="content pl-2" onClick={() => { markSeen(item.id, item.node_url) }}>
                    <div className="w-100">
                      {parse(item.node_type)}
                    </div>
                    <div className="timmer">
                      <small>Lúc {item.time}</small>
                    </div>
                  </div>
                  {item.seen !== 1 &&
                    <div className="btn-seen">
                      <button className="btn p-0" type="button" title="Đánh dấu là đã xem" onClick={() => { markSeen(item.id) }}>
                        <i className="far fa-dot-circle"></i>
                      </button>
                    </div>
                  }
                </div>)
              })
                :
                <p className="p-2">Không có thông báo nào</p>
              }
            </div>
          </div>
          <button type="button" className="navbar-toggler sidebar-toggle-mobile" >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div className={`header-main-menu collapse navbar-collapse ${showMobile ? "show" : ""}`} id="mobile-navbar">
          {info && (<>
            <ul className="hidden-xs navbar-nav">
              <li className={`navbar-item dropdown header-admin ${show ? "show" : ""}`} >
                <a
                  className="navbar-nav-link dropdown-toggle"
                  href="#shool"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setShow(!show)}
                >
                  <div className="admin-title">
                    <h5 className="item-title">
                      {info.fullname} {" "}
                    </h5>
                    <span>{info.chucvu}</span>
                  </div>
                  <div className="admin-img">
                    {uAvatar ?
                      <img src={uAvatar} alt="Admin" />
                      :
                      <img src={info && info.avatar ? info.avatar : Avatar} alt="Admin" />
                    }
                  </div>
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`} >
                  <div className="item-header">
                    <h6 className="item-title">
                      {info.fullname} {" "}
                    </h6>
                  </div>
                  <div className="item-content">
                    <ul className="settings-list">
                      <li>
                        <Link to="/my-profile">
                          <i className="flaticon-user"></i>Hồ sơ của tôi
                        </Link>
                      </li>
                      <li>
                        <a href="#logout" onClick={dologout}>
                          <i className="flaticon-turn-off"></i>Đăng xuất
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className={"navbar-item notification-bar dropdown " + (showNoti ? 'show' : '')}>
                <a className="navbar-nav-link dropdown-toggle"
                  href="#shool"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <i className="fas fa-bell"></i>
                  {countNoti > 0 && <span className="count-number">{countNoti}</span>}
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${showNoti ? "show" : ""}`} >
                  <div className="w-100 d-flex justify-content-end border-bottom">
                    <button className="btn readall" type="button" onClick={() => markSeenAll()}>Đánh dấu đọc tất cả</button>
                  </div>
                  {listNotification ? listNotification.map((item, i) => {
                    return (<div className={"notification-item p-2 " + (item.seen === 1 ? "seen" : '')} key={i}>
                      {item.avatar ?
                        <img src={item.avatar} alt="" className="avatar" />
                        :
                        ''
                      }
                      <div className="content pl-2" onClick={() => { markSeen(item.id, item.node_url) }}>
                        <div className="w-100">
                          {parse(item.node_type)}
                        </div>
                        <div className="timmer">
                          <small>Lúc {item.time}</small>
                        </div>
                      </div>
                      {item.seen !== 1 &&
                        <div className="btn-seen">
                          <button className="btn p-0" type="button" title="Đánh dấu là đã xem" onClick={() => { markSeen(item.id) }}>
                            <i className="far fa-dot-circle"></i>
                          </button>
                        </div>
                      }
                    </div>)
                  })
                    :
                    <p className="p-2">Không có thông báo nào</p>
                  }
                </div>
              </li>
            </ul>
            <div className="info-user-mobile">
              <div className="w-100 d-flex  u-info">
                <div className="admin-img">
                  <img src={info && info.avatar ? info.avatar : Avatar} alt="Admin" />
                </div>

                <div className="admin-title">
                  <h5 className="item-title">
                    {info.fullname} {" "}
                  </h5>
                  <span>{info.chucvu}</span>
                </div>

              </div>
              <ul className="settings-list">
                <li>
                  <Link to="/my-profile">
                    <i className="flaticon-user"></i>Hồ sơ của tôi
                  </Link>
                </li>
                <li>
                  <a href="#logout" onClick={dologout}>
                    <i className="flaticon-turn-off"></i>Đăng xuất
                  </a>
                </li>
              </ul>

            </div>
          </>
          )}
        </div>
      </div>
      {/* <div className="notification">
          {notifi && notifi.map((item,i)=>{
            return <div className="item-notification" key={i}>
              <div className="alert alert-sm alert-info alert-dismissible" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                {item}
              </div>
            </div>
          })}
      </div> */}
      {/* Header Menu Area End Here */}
    </>
  );
}
export default onClickOutside(HeaderBar, {
  handleClickOutside: () => HeaderBar["handleClickOutside_header"],
});

import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import AlertErrorForm from "../component/AlertErrorForm";
import SearchBoxModal from "../component/SearchBoxModal";
import { checkRole } from "../model";

export default function FormXinNghi() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [dateSelect, setDateSelect] = useState({ date_start: null, date_end: null });
    const [songayphep, setSongayphep] = useState(null)
    const [songaybu, setSongaybu] = useState(null)
    const [checkerCode, setCheckerCode] = useState(null)
    const dateNow = new Date();
    const dateset = new Date().getDay() <= 2 ? 4 : 3
    const backDate = new Date(dateNow.setDate(dateNow.getDate() - dateset))
    const [maxDatePicker, setMaxDatePicker] = useState(new Date(dateNow.setMonth(dateNow.getMonth() + 8)))
    const role_edit = checkRole(user, 'list-users-break', 'editcheck');

    const defaultdetail = {
        fullname: user ? user.data.fullname : '',
        part: user ? user.data.part_title : '',
        company: user ? user.data.company_title : '',
        user_code: user ? user.data.code : '',
        type: 'Nghỉ phép',
        date_start: '',
        checker_code: detail && detail.checker_code ? detail.master_code : user.data.master_code
    }

    useEffect(function () {
        // console.log(role_edit);
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "users_break/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        setDetail(res.data.data);
                        // console.log(res.data);
                        const date_start = res.data.data.date_start
                        const date_end = res.data.data.date_end
                        setDateSelect({ date_start: date_start, date_end: date_end })
                        setCheckerCode(res.data.data.checker_code ? res.data.data.checker_code : res.data.master_code)
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        } else {
            Axios.get(URL_LIST_ALL + "users/" + user.data.id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        let info = res.data.data
                        setCheckerCode(info.checker_code ? info.checker_code : info.master_code)
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [user, id, refresh]);// eslint-disable-line
    useEffect(function () {
        if (detail) {
            const date_start = detail.date_start
            const date_end = detail.date_end
            let newDate = Object.assign({}, dateSelect)
            newDate['date_start'] = date_start
            newDate['date_end'] = date_end
            setDateSelect(newDate)

        }
    }, [detail]);// eslint-disable-line

    useEffect(function () {
        let uCode = user.data.code
        if (detail) uCode = detail.user_code
        Axios.get(URL_LIST_ALL + "cong_nhansu?user_code=" + uCode, user.config)
            .then(async (res) => {
                if (res.data.status === "success") {
                    if (res.data.data && res.data.data[0]) {
                        const congnhasu = res.data.data[0]
                        setSongayphep(congnhasu.songayphep)
                        setSongaybu(congnhasu.songaybu)
                    }
                } else console.log(res.data.message);
            })
            .catch((err) => console.log(err));
    }, [user, detail]);// eslint-disable-line

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            // reason: Yup.string().trim().nullable().required('Vui lòng nhập lý do').max(300, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {

            } else {
                values.status = 0;
            }
            if (!values.reason && values.reason === '') {
                confirmAlert({
                    title: 'Cảnh báo',
                    message: 'Bạn vui lòng nhập lý do',
                    buttons: [{ label: 'OK' }]
                });
            } else {
                if (id > 0) {
                    Axios.put(URL_LIST_ALL + 'users_break/' + id, values, user.config).then(res => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {
                            resetForm();
                            confirmAlert({
                                title: 'Thành công',
                                message: 'Bạn cập nhật Đăng ký nghỉ, đi muộn, về sớm, công tác thành công',
                                buttons: [{ label: 'OK' }]
                            });
                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                } else {
                    Axios.post(URL_LIST_ALL + 'users_break', values, user.config).then(res => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {
                            resetForm();
                            confirmAlert({
                                title: 'Thành công',
                                message: 'Bạn thêm mới Đăng ký nghỉ, đi muộn, về sớm, công tác thành công',
                                buttons: [{
                                    label: 'OK', onClick: () => {
                                        window.location.href = "/list-users-break"
                                    },
                                }]
                            });
                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                }
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        let dateSelected = null
        if (value) {
            let month = value.getMonth() + 1
            let year = value.getFullYear()
            let day = value.getDate()
            dateSelected = new Date(year + '/' + month + '/' + day + ' 00:00:00')
        }

        newDate[name] = dateSelected ? dateSelected : value
        setDateSelect(newDate)
        // console.log(value);
        if (name === 'date_start') coreMaxdate(newDate.date_start)
    };

    const coreMaxdate = (date_start) => {
        if (date_start) {
            let month = date_start.getMonth() + 1
            let year = date_start.getFullYear()
            let day = date_start.getDate()
            if (day > 25) {
                setMaxDatePicker(new Date(year + '/' + (month + 1) + '/' + 25))
            } else {
                let dayNow = Math.round(new Date().getTime() / 100000000)
                if ((Math.round(date_start.getTime() / 100000000) + 1) < dayNow) {
                    let dateset = new Date(year + '/' + month + '/' + day + ' 23:59:59')

                    setMaxDatePicker(dateset)
                } else {
                    setMaxDatePicker(new Date(year + '/' + month + '/' + 25))
                }
            }
        }

    }


    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-users-break">Danh sách đăng ký nghỉ, đi muộn, về sớm, công tác</Link>
                    </li>
                    <li>{id ? 'Cập nhập nghỉ, đi muộn, về sớm, công tác' : 'Đăng ký nghỉ, đi muộn, về sớm, công tác'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Đăng ký nghỉ, đi muộn, về sớm, công tác</h3>
                        </div>
                    </div>
                    {id > 0 && detail && detail.status === 1 ?
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <table className="table table-borderless table-infomation">
                                    <tbody>
                                        {detail && <>
                                            <tr>
                                                <td><b>Họ và tên:</b> </td>
                                                <td>{detail.fullname ? detail.fullname : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Mã số nhân viên:</b> </td>
                                                <td>{detail.user_code ? detail.user_code : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Phòng ban:</b> </td>
                                                <td>{detail.part ? detail.part : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Công ty:</b> </td>
                                                <td>{detail.company ? detail.company : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Đăng ký:</b> </td>
                                                <td>{detail.type ? detail.type : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Lý do:</b> </td>
                                                <td>{detail.reason ? detail.reason : ''}</td>
                                            </tr>
                                            {detail.time_break &&
                                                <tr>
                                                    <td><b>Thời gian đăng ký:</b> </td>
                                                    <td>{detail.time_break ? detail.time_break : ''}</td>
                                                </tr>
                                            }
                                            {(detail.type === 'Nghỉ phép' || detail.type === 'Nghỉ bù' || detail.type === 'Nghỉ không lương' || detail.type === 'Công tác' || detail.type === 'Làm việc ngoài văn phòng' || detail.type === 'Nghỉ ốm') &&
                                                <>
                                                    <tr>
                                                        <td><b>Số ngày nghỉ/công tác:</b> </td>
                                                        <td>
                                                            {user.data.role_id == 1 || user.data.role_id == 6 ?
                                                                <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                                                                    <input type="hidden" className="form-control height32" {...formik.getFieldProps("status")} autoComplete="off" />
                                                                    <div className="d-flex">
                                                                        <input type="text" placeholder="vd: 0.5" className="form-control height32" {...formik.getFieldProps("number")} autoComplete="off" />
                                                                        <button type="submit" disabled={formik.isSubmitting} className="btn btn-sm btn-success">{loading ? <Spinner /> : <span className="fas fa-save"></span>}</button>
                                                                    </div>
                                                                </form>
                                                                :
                                                                <> {detail && detail.number ? detail.number : ''}</>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Ngày bắt đầu:</b> </td>
                                                        <td>{detail && detail.date_start ? detail.date_start : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Ngày kết thúc:</b> </td>
                                                        <td>{detail && detail.date_end ? detail.date_end : ''}</td>
                                                    </tr>
                                                </>
                                            }
                                        </>}

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 mt-3"></div>

                            <div className="col-sm-4 col-12">
                                <label className="w-100"><b>{detail && detail.checker_name ? detail.checker_name : null}</b></label>
                                {detail.status === 0 && <span className="text-warning">Chờ duyệt</span>}
                                {detail.status === 1 && <span className="text-success">Đã phê duyệt</span>}
                                {detail.status === 2 && <span className="text-danger">Không phê duyệt</span>}
                                {detail.status === 3 && <span className="text-info">Đang xem xét</span>}
                                {detail.comment && <div className="w-100 text-left mt-2">
                                    <b>Ý kiến:</b> {detail.comment}
                                </div>}
                            </div>
                        </div>
                        :
                        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <label>Họ và tên <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <label>Mã số nhân viên <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("user_code")} autoComplete="off" />
                                </div>
                                <div className="col-12"></div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <label>Phòng ban </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("part")} autoComplete="off" />
                                </div>

                                <div className="col-12 col-sm-6 col-lg-4">
                                    <label>Công ty </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("company")} autoComplete="off" />
                                </div>
                                <div className="col-12"></div>
                                <div className="col-lg-4 col-12 form-group">
                                    <label>Loại đăng ký</label>
                                    <select className="form-select height32" {...formik.getFieldProps("type")}>
                                        <option value="Nghỉ phép">Nghỉ phép</option>
                                        <option value="Nghỉ ốm">Nghỉ ốm, vợ đẻ (Nghỉ hưởng lương bảo hiểm xã hội)</option>
                                        <option value="Nghỉ chế độ">Nghỉ chế độ công ty(Ma chay, hiếu hỷ)</option>
                                        <option value="Nghỉ bù">Nghỉ bù</option>
                                        <option value="Nghỉ không lương">Nghỉ không lương</option>
                                        <option value="Công tác">Đăng ký công tác</option>
                                        <option value="Đi muộn">Đăng ký đi muộn</option>
                                        <option value="Về sớm">Đăng ký về sớm</option>
                                        {/* <option value="Không chấm công vào">Đăng ký không chấm công vào</option>
                                        <option value="Không chấm công ra">Đăng ký không chấm công ra</option> */}
                                        <option value="Làm việc ngoài văn phòng">Làm việc ngoài văn phòng</option>
                                    </select>
                                </div>

                                <div className="col-lg-4 col-12 form-group">
                                    <label>Lý do <span className="text-danger">*</span></label>
                                    <textarea className="form-control" {...formik.getFieldProps("reason")}></textarea>
                                    <AlertErrorForm formik={formik} name="reason"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>
                                {(formik.values['type'] === 'Nghỉ phép' || formik.values['type'] === 'Nghỉ bù' || formik.values['type'] === 'Nghỉ không lương' || formik.values['type'] === 'Công tác' || formik.values['type'] === 'Nghỉ chế độ' || formik.values['type'] === 'Nghỉ ốm') ?
                                    <>
                                        <div className="col-lg-8 col-12 form-group">
                                            <label>Số ngày {formik.values['type']} <span className="text-danger">*</span></label>
                                            <input type="text" placeholder="vd: 0.5" className="form-control height32" {...formik.getFieldProps("number")} autoComplete="off" />
                                            {formik.values['type'] === 'Nghỉ phép' && <h3 className="text-danger">(Bạn còn {songayphep ? songayphep : 0} ngày phép)</h3>}
                                            {formik.values['type'] === 'Nghỉ bù' && <h3 className="text-danger">(Bạn còn {songaybu ? songaybu : 0} ngày nghỉ bù)</h3>}
                                            {/* <span>Nhân sự đề xuất chữ to y như này</span> */}
                                        </div>
                                        <div className="col-12"></div>
                                        <div className="col-lg-8 col-12 form-group">
                                            <label>Ca <small>(Chỉ chọn nếu bạn nghỉ nửa ngày)</small></label>
                                            <select className="form-select height32" {...formik.getFieldProps("ca")}>
                                                <option value="">Chọn ca</option>
                                                <option value="sa">Ca sáng</option>
                                                <option value="ch">Ca chiều</option>
                                            </select>
                                        </div>

                                        <div className="col-12"></div>
                                        <div className="col-md-8 col-12 form-group">
                                            <label>Thời gian nghỉ/ công tác </label>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-8 col-12 form-group">
                                                    <label>Từ ngày: <span className="text-danger">*</span></label>
                                                    <DateTimePicker onChange={(value) => { handleDate('date_start', value) }} value={dateSelect.date_start ? new Date(dateSelect.date_start) : null} format="dd-MM-y" className="form-control height32 air-datepicker"
                                                        hourPlaceholder="giờ" minutePlaceholder="phút" secondPlaceholder="giây" yearPlaceholder="YY" monthPlaceholder="MM" dayPlaceholder="DD" minDate={dateSelect.date_start ? new Date(dateSelect.date_start) : backDate}></DateTimePicker>
                                                    <AlertErrorForm formik={formik} name="date_start"></AlertErrorForm>
                                                </div>
                                                <div className="col-xl-6 col-lg-8 col-12 form-group">
                                                    <label>Đến ngày: <span className="text-danger">*</span></label>
                                                    <DateTimePicker onChange={(value) => { handleDate('date_end', value) }} value={dateSelect.date_end ? new Date(dateSelect.date_end) : ''} format="dd-MM-y" className="form-control height32 air-datepicker"
                                                        minDate={dateSelect.date_start ? new Date(dateSelect.date_start) : new Date()} maxDate={maxDatePicker}></DateTimePicker>
                                                    <AlertErrorForm formik={formik} name="date_end"></AlertErrorForm>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                        {formik.values['type'] !== 'Không chấm công vào' && formik.values['type'] !== 'Không chấm công ra' && formik.values['type'] !== 'Làm việc ngoài văn phòng' &&
                                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                                <label>Thời gian đi muộn/ về sớm: </label>
                                                <input type="time" placeholder="Thời gian" className="form-control height32" {...formik.getFieldProps("time_break")} />
                                            </div>
                                        }
                                        <div className="col-xl-4 col-lg-6 col-12 form-group">
                                            <label>Ngày: </label>
                                            <DateTimePicker onChange={(value) => { handleDate('date_start', value) }} value={dateSelect.date_start ? new Date(dateSelect.date_start) : null} format="dd-MM-yy" className="form-control height32 air-datepicker"
                                                hourPlaceholder="giờ" minutePlaceholder="phút" secondPlaceholder="giây" yearPlaceholder="YY" monthPlaceholder="MM" dayPlaceholder="DD" minDate={dateSelect.date_start ? new Date(dateSelect.date_start) : backDate}></DateTimePicker>
                                        </div>
                                    </>
                                }
                                {role_edit ?
                                    <div className="col-xl-4 col-12 form-group">
                                         <label>Trạng thái</label>
                                        <select className="form-select height32" {...formik.getFieldProps("status")}>
                                            <option value="0">Chờ duyệt</option>
                                            <option value="1">Đã phê duyệt</option>
                                            <option value="2">Từ chối duyệt duyệt</option>
                                            <option value="3">Xem xét</option>
                                            <option value="4">Chờ gửi</option>
                                            <option value="6">Hết hạn phê duyệt</option>
                                        </select>
                                    </div>
                                    : ''
                                }

                                <div className="col-12 mt-3"></div>

                                <div className="col-xl-6 col-lg-8 col-12 form-group">
                                    <label>Người phê duyệt </label>
                                    <SearchBoxModal apiname={"users"} placeholder="Chọn người phê duyệt" colvalue={'code'} colname={'fullname'} defaultValue={checkerCode} name='checker_code' disabled={!role_edit}
                                        onChange={async (e) => {
                                            await formik.setFieldValue('checker_code', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="checker_code"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-12 form-group mg-t-8">
                                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu đăng ký</>}</button>
                                    <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </AkkhorLayout>
    );
}
